<template>
  <tr>
    <td>{{ product.id }}<br>
      <b-dropdown v-if="!pagaName" variant="link" no-caret>
        <template #button-content>
          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
        </template>
        <b-dropdown-item @click="editProduct">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Düzenle</span>
        </b-dropdown-item>
        <b-dropdown-item @click="addNewOrder(product.id)">
          <feather-icon icon="ShoppingCartIcon"/>
          <span class="align-middle ml-50">Sipariş Oluştur</span>
        </b-dropdown-item>
        <b-dropdown-item @click="tyMatching(product.id)">
          <feather-icon icon="PlusCircleIcon"/>
          <span class="align-middle ml-50">TY SKU Ekle</span>
        </b-dropdown-item>
        <b-dropdown-item @click="hbMatching(product.id)">
          <feather-icon icon="PlusCircleIcon"/>
          <span class="align-middle ml-50">HB SKU Ekle</span>
        </b-dropdown-item>
        <b-dropdown-item @click="azMatching(product.id)">
          <feather-icon icon="PlusCircleIcon"/>
          <span class="align-middle ml-50">AZ SKU Ekle</span>
        </b-dropdown-item>
        <b-dropdown-item @click="deleteProduct">
          <feather-icon icon="TrashIcon"/>
          <span class="align-middle ml-50">Sil</span>
        </b-dropdown-item>
      </b-dropdown>
      <b-button
          v-if="pagaName=='addBundle'"
          variant="primary"
          :disabled="product.isBundle==1"
          @click="addBundle"
      >Takıma Ekle
      </b-button>
    </td>
    <td @click="showImages">
      <div class="clearfix" v-if="product.images[0]">
        <b-img :src="'https://api.kitapfirsatlari.com/' + product.images[0].url" width="150"/>
      </div>
    </td>
    <td>{{ product.code }}<br>
      {{ product.barcode }}<br>
      {{ product.name }}


      <div v-if="product.barcodes">
        Barkod Listesi
        <ul>
          <li v-if="[1,2].includes(barcode.status_id)" v-for="barcode in product.barcodes" :key="barcode.id">
            {{ barcode.barcode }}
          </li>
        </ul>
      </div>
      <div v-if="!pagaName">
        <b-badge
            v-if="product.id"
            variant="success"
            size="sm"
            v-b-popover.hover.top="'Ürün Bİlgileri Düzenle'"
            @click="editProduct"
        >
          <feather-icon icon="EditIcon"/>
        </b-badge>
        <b-badge
            v-if="product.id"
            variant="warning"
            size="sm"
            v-b-popover.hover.top="'Amz Sipariş Oluştur'"
            @click="addNewOrder(product.id)">
          <feather-icon icon="ShoppingCartIcon"/>
        </b-badge>
        <b-badge
            v-if="product.id"
            variant="info"
            size="sm"
            v-b-popover.hover.top="'Satış İstatistikleri'"
            @click="getOrderProductStatistics($event,product.id)">
          <feather-icon icon="BarChart2Icon"/>
        </b-badge>
        <b-badge
            variant="warning"
            v-b-popover.hover.top="'Yeni Barkod Ekle'"
            @click="addProductBarcode(product.id)"
        >
          <b-icon-bookmark-plus></b-icon-bookmark-plus>
        </b-badge>
        <b-badge
            v-if="['admin','manager'].includes(user.role)"
            variant="success"
            v-b-popover.hover.top="'Barkod Listesi Düzenle'"
            @click="getProductBarcodeList($event,product.id)"
        >
          <b-icon-bookmarks></b-icon-bookmarks>
        </b-badge>

        <b-badge
            v-if="user.roles.includes('manager')"
            variant="warning"
            v-b-popover.hover.top="'KDV Düzelt'"
            @click="vatSelector"
        >
          <feather-icon icon="DollarSignIcon"/>
        </b-badge>

        <b-badge
            variant="info"
            v-b-popover.hover.top="'Stokları yeniden hesapla'"
            @click="recalculateInventory([product.id])">
          <feather-icon icon="PackageIcon"/>
        </b-badge>

        <b-badge
            variant="primary"
            v-b-popover.hover.top="'Mağaza detayları'"
            @click="storeProductDetail($event,product.id)">
          <feather-icon icon="PackageIcon"/>
        </b-badge>


      </div>
      <TaxRateSelector :tax-rate="product.tax_rate" :productId="product.id" :indexId="product.id"/>
    </td>

    <td v-if="_selectedFilter.indexOf('product-publishers')>-1">
      <ProductPublisher
          v-for="publisher in product.publishers"
          :key="publisher.id"
          :publisher="publisher"
          :editStatus="false"
      ></ProductPublisher>
    </td>

    <td v-if="_selectedFilter.indexOf('product-categories')>-1">
      <ProductCategory
          v-for="category in product.categories"
          :key="category.id"
          :category="category"
          :editStatus="false"
      ></ProductCategory>
    </td>

    <td v-if="_selectedFilter.indexOf('supplier-product')>-1">
      <table class="table table-hover table-sm">
        <thead>
        <tr>
          <th>Tadarikçi</th>
          <th>Resim</th>
          <th>Bilgi</th>
          <th>Adet</th>
          <th>Fiyat</th>
          <th>Termin</th>
        </tr>
        </thead>
        <tbody>
        <SupplierProduct
            v-for="supplierProduct in product.supplier_products"
            :key="supplierProduct.id"
            :product="supplierProduct"/>
        </tbody>
      </table>
    </td>

    <td v-if="_selectedFilter.indexOf('store-product')>-1">
      <StoreProducts
          v-if="product.store_products"
          :storeProdcuts="product.store_products"
      ></StoreProducts>
    </td>

    <td v-if="_selectedFilter.indexOf('product-detail')>-1">
      {{
        new Date(product.created_at).toLocaleString("tr-TR", {
          dateStyle: "short",
          timeStyle: "short",
        })
      }} <br>

      {{
        new Date(product.updated_at).toLocaleString("tr-TR", {
          dateStyle: "short",
          timeStyle: "short",
        })
      }}
    </td>
    <td>
    </td>
  </tr>
</template>

<script>
import StoreProducts from "@/views/store-product/Products";
import SupplierProduct from "@/views/supplier-product/Product";
import ProductPublisher from "@/views/product-publisher/Publisher"
import ProductCategory from "@/views/product-category/Category"
import {mapGetters} from "vuex";
import addNewOrderMixins from "@/mixins/addNewOrderMixins";
import addMpMatching from "@/mixins/addMpMatching";
import TaxRateSelector from "@/components/TaxRateSelector.vue";
import recalculateInventoryMixins from "@/mixins/recalculateInventoryMixins";

export default {
  name: "Products",
  components: {StoreProducts, SupplierProduct, ProductPublisher, ProductCategory, TaxRateSelector},
  mixins: [addNewOrderMixins, addMpMatching, recalculateInventoryMixins],
  data: () => ({
    user: JSON.parse(localStorage.getItem('userData')),
  }),
  computed: {
    ...mapGetters("product", ["_selectedFilter"])
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    pagaName: {
      type: String,
      default: null,
    }
  },
  methods: {
    storeProductDetail(event, productId) {
      this.$root.$emit('productStoreDetailProductId', productId);
      this.$root.$emit('bv::show::modal', 'product-store-detail', event.target)
    },
    async vatSelector() {
      /* inputOptions can be an object or Promise */
      const {value: vatRate} = await this.$swal.fire({
        title: 'KDV Oranı Seçiniz',
        input: 'radio',
        inputOptions: {'0': '%0', '1': '%1', '8': '%8', '18': '%18'},
        inputValidator: (value) => {
          if (!value) {
            return 'Bir KDV oranı seçmelisiniz!'
          }
        }
      })

      if (vatRate) {
        await this.$store.dispatch('product/updateProduct', {id: this.product.id, tax_rate: vatRate})
            .then(res => {
              //this.$swal({html: `${res.message} (${vatRate})`})
            })
      }
    },
    addProductBarcode(productId) {
      let newBarcode = prompt("Lütfen Yeni Barkodu Giriniz", "");
      if (newBarcode != null) {
        this.$store.dispatch('product/storeProductBarcode', {product_id: productId, barcode: newBarcode})
            .then(res => {
              this.$swal({
                title: res.message,
                icon: res.icon,
              })
            })
      }
    },
    getProductBarcodeList(event, productId) {
      this.$store.commit('product/setEditProduct', this.product);
      this.$root.$emit('bv::show::modal', 'edit-product-barcodes-modal', event.target)
    },
    getOrderProductStatistics(event, produdctId) {
      this.$store.state.order.orderProductStatisticsProductId = produdctId;
      this.$root.$emit('bv::show::modal', 'order-product-statistics-modal', event.target)
    },
    deleteProduct() {
      this.$swal({
        title: 'SKU Girin',
        text: "Pazaryeri SKU No Girin",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
      /*
      this.$store.dispatch('product/deleteProduct', this.product.id)
          .then(res => {
            alert(res.message)
          })

       */
    },
    showImages($event) {
      this.$store.commit('product/setEditProduct', this.product);
      this.$root.$emit('bv::show::modal', 'product-images-modal', $event.target)
    },
    editProduct($event) {
      this.$store.commit('product/setEditProduct', this.product);
      this.$root.$emit('bv::show::modal', 'edit-product-modal', $event.target)
    },
    addBundle() {
      this.$store.dispatch('product/storeProductSubProduct', this.product)
          .then(res => {
            this.$swal({
              title: res.message,
              icon: res.status,
              timer: 1000,
            });
          })
    }
  }
}
</script>

<style scoped>

</style>
