<template>
  <b-tab title="Takım Ürün">
    <b-card no-body>
      <b-row no-gutters>
        <b-card-body>

        </b-card-body>
        <b-card-footer>
          <b-button variant="success" @click="addSubProduct">Ürün ekle</b-button>
        </b-card-footer>
      </b-row>
    </b-card>
    <section class="list-view" v-if="product.sub_products">
      <b-card
          v-for="(sub_product) in product.sub_products"
          :key="sub_product.id"
          class="overflow-hidden"
          no-body
      >
        <b-row no-gutters>
          <div v-if="sub_product.images">
            <div v-if="sub_product.images.length>0">
              <b-card-img
                  v-for="(image,imgIndex) in sub_product.images"
                  v-if="imgIndex==0"
                  :key="image.id"
                  :src="'https://api.kitapfirsatlari.com/'+image.url"
                  alt="Image"
                  width="250"
                  left
              />
            </div>
            <div v-else>
              <b-card-img src="#" alt="Image" left/>
            </div>
          </div>
          <div v-else>
            <b-card-img src="#" alt="Image" left/>
          </div>

          <b-card-body>
            <b-card-title>Ürün Adı: {{ sub_product.name }}</b-card-title>
            <b-card-sub-title class="mb-2">Barkod : {{ sub_product.barcode }}</b-card-sub-title>
            <b-card-sub-title class="mb-2">Ürün No: {{ sub_product.id }}</b-card-sub-title>
            <b-card-sub-title class="mb-2">
              Marka :
              <span v-if="sub_product.brand">{{ sub_product.brand.name }}</span>
            </b-card-sub-title>
          </b-card-body>

          <b-card-body>
            <b-form>
              <b-form-group label-cols="6" label="Kat Sayı" label-for="multiple" size="sm">
                <b-form-input size="sm" id="multiple" placeholder="Normal Input" v-model="sub_product.pivot.multiple"/>
              </b-form-group>
              <b-form-group label-cols="6" label="Yüzde İlave">
                <b-input-group size="sm">
                  <b-input-group-prepend>
                    <b-form-select
                        size="sm"
                        v-model="sub_product.pivot.percent_sing"
                        :options="[{value:'+',text:' + %'},{value:'-',text:' - %'}]"
                    />
                  </b-input-group-prepend>
                  <b-form-input type="number" v-model="sub_product.pivot.percent"/>
                </b-input-group>
              </b-form-group>

              <b-form-group label-cols="6" label="Sabit İlave">
                <b-input-group size="sm">
                  <b-input-group-prepend>
                    <b-form-select
                        size="sm"
                        v-model="sub_product.pivot.fixed_sing"
                        :options="[{value:'+',text:' +   '},{value:'-',text:' -   '}]"
                    />
                  </b-input-group-prepend>
                  <b-form-input type="number" v-model="sub_product.pivot.fixed"/>
                </b-input-group>
              </b-form-group>


              <b-row>
                <b-col md="8">
                  <b-button-group class="mt-1">
                    <b-button @click="sub_product.pivot.status_id=2" size="sm" variant="success"> Onayla</b-button>
                    <b-button @click="sub_product.pivot.status_id=4" size="sm" variant="danger"> Reddet</b-button>
                  </b-button-group>
                </b-col>
                <b-col md="4">
                  <b-button class="mt-1" :variant="matching_status_color(sub_product.pivot.status_id)" disabled>{{
                      matching_status(sub_product.pivot.status_id)
                    }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>

          </b-card-body>
          <b-card-footer>
            <b-button-group vertical size="sm">
              <b-button v-if="sub_product.pivot.id" variant="primary" @click="updatePivot(sub_product.pivot)">
                Güncelle
              </b-button>
              <b-button v-if="sub_product.pivot.id" variant="danger" @click="destroyPivod(sub_product.pivot.id)">
                Çıkart
              </b-button>
            </b-button-group>
          </b-card-footer>
        </b-row>
      </b-card>
    </section>
  </b-tab>
</template>

<script>
import matchingStatusMixins from "@/mixins/matchingStatusMixins";

export default {
  name: "EditBundle",
  mixins: [matchingStatusMixins],
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    addSubProduct($event) {
      this.$root.$emit('bv::show::modal', 'edit-bundle-add-product-home-modal', $event.target)
    },
    updatePivot(pivot) {
      this.$store.dispatch('product/updateProductSubProduct', pivot)
          .then(res => {
            this.$swal({
              title: res.message,
              icon: res.stats
            });
          })
    },
    destroyPivod(pivotId) {
      this.$store.dispatch('product/destroyProductSubProduct', pivotId)
          .then(res => {
            this.$swal({
              title: res.message,
              icon: res.stats
            });
          })
    }
  }
}
</script>



