<template>
  <b-modal ref="del-supplier-product-stock"
           id="del-supplier-product-stock"
           hide-footer
           size="lg"
           title="Stokları Silinecek Tedarikçi Ürünleri">
    <b-overlay :show="show" rounded="sm">
      <b-row v-if="supplierProducts.length>0">
        <b-col>
          <b-row>
            <b-col
                v-for="supplierProduct in supplierProducts"
                :key="supplierProduct.id"
                cols="4"
            >
              <b-form-checkbox
                  v-model="selected"
                  :value="supplierProduct.id"
              >
                {{ supplierProduct.supplier.name }}
                <b-badge variant="primary">{{ supplierProduct.stock }}</b-badge>
              </b-form-checkbox>

            </b-col>
          </b-row>
          <hr>

          <b-row>
            <b-col>
              <b-button
                  :disabled="selected.length<1"
                  variant="success"
                  @click="delStockSupplierProduct"
              >Stokları Sıfırla
                <b-badge v-if="selected.length>0" variant="danger">
                  {{ selected.length }}
                </b-badge>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col>
          <b-badge>Stok sıfırlamaya uygun tedarikçi ürünü yoktur</b-badge>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import axios from "@/libs/axios";

export default {
  name: "DelSupplierProductStok",
  data: () => ({
    show: false,
    storeId: null,
    supplierProducts: [],
    selected: []
  }),
  methods: {
    delStockSupplierProduct() {
      axios.post(`/supplier/product/setstockzero`, this.selected)
          .then(res => {
            this.$swal({
              title: 'Stok silme başarılı',
              icon: 'success',
              timer: 1000,
              position: 'top-end',
            })
          })
    }
  },
  mounted() {
    this.$root.$on('delSupplierProductStock', (payload) => {
      this.show = true;
      this.selected = [];
      axios.get(`/supplier/store/${payload.storeId}/product/${payload.productId}`)
          .then(res => {
            this.storeId = payload.storeId;
            this.supplierProducts = res.data.content;
            this.show = false;
          })
    });
  }
}
</script>

<style scoped>

</style>
