import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('store', ["_stores"]),
    },
    methods: {
        store_name(id) {
            let item = this._stores.find(item => item.id == id)
            if (!item) return 'Boş'
            return item.store_name;
        }
    }
}
