var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tab',{attrs:{"title":"Ürün"}},[_c('b-card-text',[_c('b-form-group',{attrs:{"label-cols":"4","label":"Ürün No","size":"sm"}},[_c('b-form-input',{attrs:{"type":"text","size":"sm","disabled":""},model:{value:(_vm.product.id),callback:function ($$v) {_vm.$set(_vm.product, "id", $$v)},expression:"product.id"}})],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Ürün Kodu","size":"sm"}},[_c('b-form-input',{attrs:{"type":"text","size":"sm","disabled":""},model:{value:(_vm.product.code),callback:function ($$v) {_vm.$set(_vm.product, "code", $$v)},expression:"product.code"}})],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Ürün Adı","size":"sm"}},[_c('validation-provider',{attrs:{"name":"ürün adı","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","size":"sm","state":errors.length > 0 ? false:null},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Ürün Barkod","size":"sm"}},[_c('validation-provider',{attrs:{"name":"ürün barkod","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","size":"sm","state":errors.length > 0 ? false:null},model:{value:(_vm.product.barcode),callback:function ($$v) {_vm.$set(_vm.product, "barcode", $$v)},expression:"product.barcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.product.brand)?_c('fieldset',{staticClass:"form-group",attrs:{"size":"sm"}},[_c('div',{staticClass:"form-row"},[_c('legend',{staticClass:"col-4 bv-no-focus-ring col-form-label",attrs:{"tabindex":"-1"}},[_vm._v(" Marka "),_c('b-badge',{attrs:{"variant":"success","size":"sm"},on:{"click":_vm.editBrand}},[_vm._v("Edit")])],1),_c('div',{staticClass:"col"},[_c('input',{staticClass:"form-control form-control-sm",attrs:{"type":"text","disabled":"disabled"},domProps:{"value":_vm.product.brand.name}})])])]):_c('b-form-group',{attrs:{"label-cols":"4","label":"Marka","size":"sm"}},[_c('validation-provider',{attrs:{"name":"marka","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredBrands,"limit":_vm.limit,"input-props":{id:'autosuggest__input',class:'form-control form-control-sm', placeholder:'Marka Seçiniz!'}},on:{"input":_vm.onInputChange,"selected":_vm.selectHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true)})]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"4","size":"sm"}},[_c('b-badge',{attrs:{"variant":""},on:{"click":_vm.addNewBrand}},[_vm._v("Marka Oluştur")])],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"KDV Oranı","size":"sm"}},[_c('validation-provider',{attrs:{"name":"Kdv Oranı","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm._taxes,"name":"radio-inline"},model:{value:(_vm.product.tax_rate),callback:function ($$v) {_vm.$set(_vm.product, "tax_rate", $$v)},expression:"product.tax_rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Ürün Fiyatı","size":"sm"}},[_c('validation-provider',{attrs:{"name":"ürün Fiyatı","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","size":"sm","state":errors.length > 0 ? false:null},model:{value:(_vm.product.price),callback:function ($$v) {_vm.$set(_vm.product, "price", $$v)},expression:"product.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Ürün sabit ilave","size":"sm"}},[_c('validation-provider',{attrs:{"name":"ürün sabit ilave","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","size":"sm","state":errors.length > 0 ? false:null},model:{value:(_vm.product.fixed_const),callback:function ($$v) {_vm.$set(_vm.product, "fixed_const", $$v)},expression:"product.fixed_const"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Ürün Açıklama","size":"sm"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Textarea"},model:{value:(_vm.product.description),callback:function ($$v) {_vm.$set(_vm.product, "description", $$v)},expression:"product.description"}})],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Bilgiler Kimden Alındı","size":"sm"}},[_c('b-form-input',{attrs:{"type":"text","size":"sm","value":_vm.supplier_name(_vm.product.supplier_id),"disabled":""}})],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Ekleme Tarihi","size":"sm"}},[_c('b-form-input',{attrs:{"type":"text","size":"sm","value":new Date(_vm.product.created_at).toLocaleString('tr-TR', {dateStyle: 'short',timeStyle: 'short',}),"disabled":""}})],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Değiştirme Tarihi","size":"sm"}},[_c('b-form-input',{attrs:{"type":"text","size":"sm","value":new Date(_vm.product.updated_at).toLocaleString('tr-TR', {dateStyle: 'short',timeStyle: 'short',}),"disabled":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }