<template>
  <b-row
      :class="product.order_product_count!=indexNo?'border-bottom-danger':''">
    <b-col cols="12" class="p-0">
      <b-row>
        <b-col cols="2">
          <div class="imageContainer m-0 p-0" v-if="product.store_product_image_url && $store.state.order.isMpImage">
            <b-img class="border border-danger" :src="product.store_product_image_url" width="150"/>
          </div>
        </b-col>
        <b-col cols="2">
          <div class="imageContainer m-0 p-0">
            <div class="clearfix border" v-if="product.images[0]" @click="showImages">
              <b-img class="border border-primary" :src="'https://api.kitapfirsatlari.com/' + product.images[0].url"
                     width="150"/>
            </div>
            <b-badge variant="danger" class="top-left" v-if="product.quantity>1">
              {{ product.quantity }}x
            </b-badge>
          </div>
        </b-col>
        <b-col cols="5">
          <feather-icon icon="CopyIcon" @click="doCopy(product.id)"/>
          {{ product.id }}
          <b v-if="product.order_product_count>1" class="text-danger productCount">
            {{ indexNo }} / {{ product.order_product_count }}
          </b>&nbsp;&nbsp;&nbsp;

          <feather-icon icon="CopyIcon" @click="doCopy(product.bundle_product_id)" v-if="product.bundle_product_id"/>
          <b-badge variant="success" v-if="product.bundle_product_id">
            Dikkat Sanal Ürün ( {{ product.bundle_product_id }} )
          </b-badge>

          <br v-if="product.bundle_product_id">
          <feather-icon v-if="product.bundle_product_name" icon="CopyIcon"
                        @click="doCopy(product.bundle_product_name)"/>
          <b-badge variant="success" v-if="product.bundle_product_name">
            {{ product.bundle_product_name }}
          </b-badge>
          <br>
          <feather-icon icon="CopyIcon" @click="doCopy(product.product_name)"/>
          <b>{{ product.product_name }}</b><br>

          <feather-icon icon="CopyIcon" @click="doCopy(product.store_product_name)"/>
          {{ product.store_product_name }}<br>

          <div md="12" v-if="product.product">
            <div class="" v-if="product.product.brand">
              <feather-icon icon="CopyIcon" @click="doCopy(product.product.brand.name)"/>
              <b-badge>{{ product.product.brand.name }}</b-badge>
            </div>
          </div>

          Barkod:
          <b>
            <feather-icon icon="CopyIcon" @click="doCopy(product.product_barcode)"/>
            {{ product.product_barcode }}
            (
            <feather-icon icon="CopyIcon" @click="doCopy(product.product_id)"/>
            {{ product.product_id }})
          </b>

          <div v-if="product.product">
            <div v-if="product.product.barcodes.length>0">
              Listesi
              <ul>
                <li v-if="[1,2].includes(barcode.status_id)" v-for="barcode in product.product.barcodes"
                    :key="barcode.id"
                >
                  <feather-icon icon="CopyIcon" @click="doCopy(barcode.barcode)"/>
                  {{ barcode.barcode }}
                </li>
              </ul>
            </div>
          </div>
          <b-badge
              v-if="product.product_id"
              variant="success"
              size="sm"
              v-b-popover.hover.top="'Ürün Bİlgileri Düzenle'"
              @click="productEdit"
          >
            <feather-icon icon="EditIcon"/>
          </b-badge>
          <b-badge
              v-if="product.product_id"
              variant="warning"
              size="sm"
              v-b-popover.hover.top="'Amz Sipariş Oluştur'"
              @click="addNewOrder(product.product_id)"
          >
            <feather-icon icon="ShoppingCartIcon"/>
          </b-badge>
          <b-badge
              v-if="product.product_id"
              variant="info"
              size="sm"
              v-b-popover.hover.top="'Satış İstatistikleri'"
              @click="getOrderProductStatistics"
          >
            <feather-icon icon="BarChart2Icon"/>
          </b-badge>
          <b-badge
              variant="warning"
              v-b-popover.hover.top="'Yeni Barkod Ekle'"
              @click="addProductBarcode(product.product_id)"
          >
            <b-icon-bookmark-plus></b-icon-bookmark-plus>
          </b-badge>
          <b-badge
              v-if="['admin','manager'].includes(user.role)"
              variant="success"
              v-b-popover.hover.top="'Barkod Listesi Düzenle'"
              @click="getProductBarcodeList($event,product.id)"
          >
            <b-icon-bookmarks></b-icon-bookmarks>
          </b-badge>
          <b-badge
              v-if="user.roles.includes('manager')"
              variant="warning"
              v-b-popover.hover.top="'KDV Düzelt'"
              @click="vatSelector"
          >
            <feather-icon icon="DollarSignIcon"/>
          </b-badge>
          <b-badge
              variant="info"
              v-b-popover.hover.top="'Stokları yeniden hesapla'"
              @click="recalculateInventory([product.product.id])">
            <feather-icon icon="PackageIcon"/>
          </b-badge>

          <b-badge
              variant="primary"
              v-b-popover.hover.top="'Mağaza detayları'"
              @click="storeProductDetail($event,product.product.id)">
            <feather-icon icon="PackageIcon"/>
          </b-badge>

          <b-badge
              v-if="user.role==='admin'"
              variant="warning"
              v-b-popover.hover.top="'Stokları Sıfırla'"
              @click="delSupplierProductStock($event,product.product.id)">
            <feather-icon icon="PackageIcon"/>
          </b-badge>

          <br>
          <div v-if="product.product">
            <TaxRateSelector
                :tax-rate="product.product.tax_rate"
                :productId="product.product.id"
                :indexId="product.id"
            />
          </div>

          Pazaryeri SKU:
          <span class="font-weight-bold text-nowrap">
          <feather-icon icon="CopyIcon" @click="doCopy(product.store_product_sku)"/>
          {{ product.store_product_sku }}
          </span>
          <br>
          Adet:
          <b-badge :variant="product.quantity>1?'danger':'success'">{{ product.quantity }}</b-badge>
          <br>

          <b-badge variant="" style="min-width: 60px">Satış&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {{
              product.price
            }}
          </b-badge>
          <b-badge variant="" style="min-width: 60px">{{ product.total_price }}</b-badge>
          <br>
          <b-badge variant="" style="min-width: 60px" v-if="product.price!=product.sales_price">İnd.Sat. :
            {{ product.sales_price }}
          </b-badge>
          <b-badge variant="" style="min-width: 60px" v-if="product.total_price!=product.total_sales_price">
            {{ product.total_sales_price }}
          </b-badge>
          <br>
          Durumu:
          <b-badge
              variant=""
              class="badge-glow"
          >
            {{ product.order_product_status }}
          </b-badge>
          <br>

        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col cols="12">

            </b-col>
            <b-col cols="12">
              <b-overlay :show="supplierChangeShow" rounded="sm">
                <div v-if="!product.supplier_id">
                  <v-select
                      label="name"
                      :options="_suppliers"
                      :reduce="item => item.id"
                      class="select-size-sm"
                      @option:selected="openmodal"
                      placeholder="Tedarikçi Girin"
                  />
                  <!--
                  <v-select
                      label="name"
                      :options="_suppliers"
                      :reduce="item => item.id"
                      class="select-size-sm"
                      placeholder="Tedarikçi Girin"
                      v-model="selectedSupplier"
                  />
                  -->
                  <!--
                  <v-select
                      label="name"
                      :options="_suppliers"
                      :reduce="item => item.id"
                      class="select-size-sm"
                      @option:selected="selectedOption"
                      placeholder="Tedarikçi Girin"
                  />
                  -->
                </div>
                <div v-else>
                  <b-badge size="sm" variant="dark" class="badge-glow">
                    {{ supplierName(product.supplier_id) }}
                  </b-badge>
                  <b-badge size="sm" variant="" @click="selectedOption2" title="Boşalt">
                    <feather-icon icon="XIcon"/>
                  </b-badge>

                  <b-badge size="sm" variant="info" @click="openmodal2" title="Fiyat Düzelt">
                    <feather-icon icon="EditIcon"/>
                  </b-badge>

                </div>
              </b-overlay>
            </b-col>
            <b-col cols="12" v-if="selectedSupplier">
              <b-input-group prepend="Fiyat" size="sm">
                <b-input id="supplierPrice" type="number"
                         :state="false || selectedSupplierPrice>0?true:false"
                         v-model="selectedSupplierPrice"/>
                <b-input-group-append>
                  <b-button variant="outline-info"
                            :disabled="!(false || selectedSupplierPrice>0?true:false)"
                            @click="saveSupplier">
                    <b-icon icon="cloud-download" aria-hidden="true"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="12">
              <b-badge variant="info" @click="showOrderTimeLine">Geçmiş</b-badge>
            </b-col>
            <b-col cols="12" v-if="!product.supplier_id">
              <b-badge v-if="!product.supplier_can_be_chosen" variant="danger">Henüz Bitmemiş</b-badge>
            </b-col>
            <b-col cols="12" v-if="getPrices(product.product_id)">
              <div v-if="!product.supplier_id">
                <b-overlay :show="cancelBtnStatus && product.stocks.length>0">
                  <b-btn-group vertical>
                    <b-btn
                        size="sm"
                        :variant="selectVariant(price.supplier_id,productAccounting.calculated_supplier_id)"
                        v-for="price in getPrices(product.product_id).prices"
                        :key="price.id"
                        :disabled="clickedBtn || price.stock<1 || !price.price"
                        :title="new Date(price.updated_at).toLocaleString('tr-TR', {dateStyle: 'short',timeStyle:'short'})"
                        @click="setOrderProductAddBasket(price)"
                    >
                      {{ supplier_name(price.supplier_id) }}
                      <span v-if="price.price">
                      {{ price.price.toFixed(2) }} ₺
                      </span>
                      - {{ price.stock }}
                    </b-btn>
                  </b-btn-group>
                  <template #overlay>
                    <div class="text-center">
                      <b-button
                          ref="cancel"
                          variant="outline-danger"
                          size="sm"
                          aria-describedby="cancel-label"
                          @click="cancelBtnStatus = false"
                      >
                        <b-icon icon="lock-fill" font-scale="3" animation="throb"></b-icon>
                      </b-button>
                    </div>
                  </template>
                </b-overlay>
              </div>

            </b-col>
            <b-col cols="12">
              <b-button size="sm" variant="" @click="addNewMessage(null)">
                <feather-icon icon="Edit3Icon"/>
              </b-button>
            </b-col>
            <b-col cols="12">
              <OrderListProductMessages
                  v-for="message in product.messages"
                  :key="message.id"
                  :message="message"
                  :product="product"
              />
            </b-col>
            <b-col cols="12">
              <OrderProductAccounting
                  v-if="product.product_accounting && user.roles.includes('manager') && _search.profit"
                  :product="product"
                  :accounting="product.product_accounting"
              />
            </b-col>
            <b-col cols="12">
              <Accounting v-if="product.accountings" :accountings="product.accountings"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" class="pb-1">
          <b-overlay :show="addToComeBtnShow" rounded="sm">
            <div v-if="order.order_status_id==13">
              <div v-if="product.come">
                <div v-if="product.come.id">
                  <b-button size="sm" variant="warning">
                    Toplandı ({{ user_name(product.come.user_id) }})
                  </b-button>
                  <b-button size="sm" variant="danger" @click="delToCome"> Geri Al</b-button>
                </div>
              </div>
              <b-button v-else variant="warning" @click="addToCome"> Topla</b-button>
              <br>
            </div>
            <div v-else>
              <div v-if="product.come">
                <div v-if="product.come.id">
                  <b-badge size="sm" variant="warning" @click="delToCome"> Geri Al</b-badge>
                  <br>
                  <b-badge variant=""> Sıra: {{ product.come.id }}</b-badge>
                  <b-badge variant="">
                    <ViewDate name="" :date="product.come.created_at"/>
                  </b-badge>
                  <b-badge variant=""> Kull. {{ user_name(product.come.user_id) }}</b-badge>
                </div>
              </div>
              <b-badge v-if="isActiveAddToCome" size="sm" variant="warning" @click="addToCome">Geldi
              </b-badge>
            </div>
            <b-badge :variant="addToComeBtnMesaj.variant">{{ addToComeBtnMesaj.title }}</b-badge>
          </b-overlay>
          <div v-if="product.stock_card" class="">
            <b-badge variant="danger">{{ product.stock_card.shelve_name }}</b-badge>
            <br>
            <b-badge variant="danger">{{ supplierName(product.stock_card.supplier_id) }} -
              {{ product.supplier ? product.supplier.name : '***' }}
            </b-badge>
            <!--<b-badge variant="">{{ product.stock_card.add_user_id }}</b-badge>-->
          </div>
        </b-col>
        <b-col md="10">
          <b-row>
            <OrderProductSupplierProduct :product="product" :productAccounting="productAccounting"/>
            <OrderProductStock :order="order" :product="product"/>
            <OrderProductStoreLink :product="product"/>
          </b-row>
        </b-col>

      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from 'vuex'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ViewDate from '@/views/common/ViewDate'
import OrderListProductMessages from '@/views/order/list/OrderListProductMessages'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import supplierMixins from '@/mixins/supplierMixins'
import userMixins from '@/mixins/userMixins'
import axios from '@/libs/axios'
import addToComeMixins from '@/mixins/addToComeMixins'
import addNewOrderMixins from '@/mixins/addNewOrderMixins'
import Accounting from '@/views/order/list/Accounting.vue'
import OrderProductAccounting from '@/views/order/list/OrderProductAccounting'
import OrderProductSupplierProduct from '@/views/order/list/OrderProductSupplierProduct'
import OrderProductStoreLink from '@/views/order/list/OrderProductStoreLink'
import OrderProductStock from '@/views/order/list/OrderProductStock'
import ProductScanPriceMixins from '@/mixins/productScanPriceMixins'
import TaxRateSelector from "@/components/TaxRateSelector.vue";
import recalculateInventoryMixins from "@/mixins/recalculateInventoryMixins";
import copyMixins from '@/mixins/copyMixins'


export default {
  name: 'OrderListProduct',
  mixins: [supplierMixins, userMixins, addToComeMixins, addNewOrderMixins, ProductScanPriceMixins, recalculateInventoryMixins, copyMixins],
  components: {
    OrderProductStock,
    OrderProductStoreLink,
    OrderProductSupplierProduct,
    vSelect,
    ViewDate,
    OrderListProductMessages,
    AppTimeline,
    AppTimelineItem,
    Accounting,
    OrderProductAccounting,
    TaxRateSelector,
  },
  data: () => ({
    user: JSON.parse(localStorage.getItem('userData')),
    selectedSupplier: 0,
    selectedSupplierPrice: 0,
    cancelBtnStatus: true,
    supplierChangeShow: false,
    clickedBtn: false,
    addToComeBtnShow: false,
    addToComeBtnMesaj: {
      title: '',
      variant: '',
    },
  }),
  props: {
    order: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    productCount: {
      type: Number,
      required: true
    },
    indexNo: {
      type: Number,
      required: true,
    }
  },
  computed: {
    ...mapGetters('supplier', ['_suppliers']),
    ...mapGetters('order', ['_search']),
    taxes() {
      let taxes = {};
      this.$store.state.app.taxes.forEach(tax => {
        if (!tax.disabled) taxes[tax.value] = tax.text;
      })
      return taxes
    },
    isActiveAddToCome() {
      if (this.product.order_product_count > 1 && this.order.order_status_id == 3) {
        if ([9, 27].includes(this.product.supplier_id)) {
          return false
        }
        if (!this.product.come) {
          return true
        } else if (!this.product.come.id) return true
      }
      return false
    },
    productAccounting() {
      if (this.product.product_accounting) return this.product.product_accounting
      return {}
    },
  },
  methods: {
    delSupplierProductStock(event, productId) {
      this.$root.$emit('delSupplierProductStock', {storeId: this.order.store_id, productId});
      this.$root.$emit('bv::show::modal', 'del-supplier-product-stock', event.target)
    },
    storeProductDetail(event, productId) {
      this.$root.$emit('productStoreDetailProductId', productId);
      this.$root.$emit('bv::show::modal', 'product-store-detail', event.target)
    },
    async vatSelector() {
      /* inputOptions can be an object or Promise */
      const {value: vatRate} = await this.$swal.fire({
        title: 'KDV Oranı Seçiniz',
        input: 'radio',
        inputOptions: this.taxes,
        inputValidator: (value) => {
          if (!value) {
            return 'Bir KDV oranı seçmelisiniz!'
          }
        }
      })

      if (vatRate) {
        await this.$store.dispatch('product/updateProduct', {id: this.product.product_id, tax_rate: vatRate})
            .then(res => {
              this.$swal({html: `${res.message} (${vatRate})`})
            })
      }
    },
    setOrderProductAddBasket(price) {
      this.clickedBtn = true
      this.$store.dispatch('order/setOrderProductAddBasket', {
        id: this.product.id,
        price,
      }).then(res => {
        if (res.order) {
          this.order.order_status_id = res.order.order_status_id;
          this.order.status = res.order.status;
        }
        if (res.status != 'success') {
          this.clickedBtn = false
          this.$swal({
            icon: 'error',
            title: 'Sepete Ekleme Başarısız',
            text: res.message,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
    getProductBarcodeList(event, productId) {
      this.$store.commit('product/setEditProduct', this.product.product)
      this.$root.$emit('bv::show::modal', 'edit-product-barcodes-modal', event.target)
    },
    addProductBarcode(productId) {
      let newBarcode = prompt('Lütfen Yeni Barkodu Giriniz', '')
      if (newBarcode != null) {
        this.$store.dispatch('product/storeProductBarcode', {
          product_id: productId,
          barcode: newBarcode
        })
            .then(res => {
              this.$swal({
                title: res.message,
                icon: res.icon,
              })
            })
      }
    },
    showImages($event) {
      axios.post('/products/all', {id: this.product.product_id})
          .then(res => {
            if (res.data.data[0]) {
              this.$store.commit('product/setEditProduct', res.data.data[0])
              this.$root.$emit('bv::show::modal', 'product-images-modal', $event.target)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ürün çekme hatası2',
                  variant: 'danger',
                  icon: 'XCircleIcon',
                }
              })
            }
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ürün çekme hatası',
                variant: 'danger',
                icon: 'XCircleIcon',
              }
            })
          })
    },
    productEdit($event) {
      axios.post('/products/all', {id: this.product.product_id})
          .then(res => {
            if (res.data.data[0]) {
              this.$store.commit('product/setEditProduct', res.data.data[0])
              this.$root.$emit('bv::show::modal', 'edit-product-modal', $event.target)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ürün çekme hatası2',
                  variant: 'danger',
                  icon: 'XCircleIcon',
                }
              })
            }
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ürün çekme hatası',
                variant: 'danger',
                icon: 'XCircleIcon',
              }
            })
          })
    },
    getOrderProductStatistics($event) {
      this.$store.state.order.orderProductStatisticsProductId = this.product.product_id
      this.$root.$emit('bv::show::modal', 'order-product-statistics-modal', $event.target)
    },
    showOrderTimeLine($event) {
      this.$store.state.order.timelineOrderProductId = this.product.id
      this.$root.$emit('bv::show::modal', 'order-list-timeline-modal', $event.target)
    },
    supplierName(id) {
      let supplier = this._suppliers.find(supplier => supplier.id == id)
      if (!supplier) return ''
      return supplier.name
    },
    changeProductSupplier() {
      this.product.supplier_id = null
    },
    saveSupplier() {
      this.supplierChangeShow = true
      if (this.selectedSupplier == this.product.supplier_id) {
        this.$store.dispatch('order/updateOrderProductPrice', {
          id: this.product.id,
          price: {selected_purchase_price: this.selectedSupplierPrice}
        })
            .then(res => {
              this.supplierChangeShow = false
              this.selectedSupplier = 0
              this.selectedSupplierPrice = 0
              this.$toast({
                component: ToastificationContent,
                props: res
              })
            })
        return
      }
      this.$store.dispatch('order/updateOrderProduct', {
        id: this.product.id,
        product: {
          supplier_id: this.selectedSupplier,
          supplier_price: this.selectedSupplierPrice,
        },
      })
          .then(res => {
            this.supplierChangeShow = false
            this.selectedSupplier = 0
            this.selectedSupplierPrice = 0
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    },
    selectedOption2() {
      if (confirm('başa düşürmek itediğinizden emin misiniz?') == true) {
        this.selectedOption({id: null})
      }
    },
    openmodal2($event) {
      this.$root.$emit('openOrderProductSupplierSelect', {
        product: this.product,
        supplier: {id: this.product.supplier_id}
      });
      this.$root.$emit('bv::show::modal', 'order-product-supplier-select', $event.target)
    },
    openmodal($event) {
      this.$root.$emit('openOrderProductSupplierSelect', {
        product: this.product,
        supplier: $event
      });
      this.$root.$emit('bv::show::modal', 'order-product-supplier-select', $event.target)
    },
    selectedOption(supplier) {
      this.supplierChangeShow = true
      let newSupplier = {
        id: supplier.id,
        code: supplier.code,
        name: supplier.name
      }
      this.$store.dispatch('order/updateOrderProduct', {
        id: this.product.id,
        product: {
          supplier_id: supplier.id,
        },
        supplier: newSupplier
      })
          .then(res => {
            this.supplierChangeShow = false
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    },
    addNewMessage(parent_id) {
      let message = prompt('Mesaj')
      if (message == null || !message) {
        alert('mesaj geçersiz')
      } else {
        message = message.trim()
        let data = {
          id: this.product.id,
          product: {
            order_product_id: this.product.id,
            parent_id,
            message,
          }
        }
        this.$store.dispatch('order/crateOrderProductMessage', data)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                props: res
              })
            })
      }
    },
    selectVariant(product, supllier) {
      if (product == supllier) return 'success'
      return 'primary'
    }
  }
}
</script>

<style>
.supplierBtn {
  background-color: #dcd8bb
}

.listGroupItem {
  font-size: 12px;
}

.imageContainer {
  position: relative;
  text-align: center;
  color: green;
}

.top-left {
  position: absolute;
  font-size: 26px;
  top: 0px;
  left: 0px;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
    font-size: 36px;
  }
  50% {
    opacity: 0;
    font-size: 15px;
  }
  100% {
    opacity: 1;
    font-size: 36px;
  }
}

</style>
<style scoped>


.productCount {
  font-size: 1.4rem;
  font-family: "Arial, Helvetica, sans-serif";
}

.border-bottom-danger {
  border-width: 5px;
  margin-bottom: 2px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>

