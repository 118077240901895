<template>
  <b-overlay :show="isSelected">
    <b-form-radio-group
        v-model="selected"
        :options="_taxes"
        :name="'rax_rate-'+indexId"/>
  </b-overlay>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "TaxRateSelector",
  props: {taxRate: Number, productId: Number, indexId: Number},
  computed: {
    ...mapGetters('app', ['_taxes']),
    selected: {
      get() {
        return this.taxRate
      },
      set(val) {
        this.isSelected = true;
        this.$store.dispatch('product/updateProduct', {id: this.productId, tax_rate: val})
            .then(res => {
              this.isSelected = false;
            })
      }
    }
  },
  data: () => ({
    isSelected: false,
  }),
  methods: {}
}
</script>

<style scoped>

</style>
