<template>
  <section id="card-text-alignment" class="mb-0">
    <OrderListSearch></OrderListSearch>
    <OrderBasket></OrderBasket>
    <OrderFilter v-if="['collet','preparing'].includes($route.meta.pageName)"/>
    <section id="order-list" v-if="!_isOrderProductList">
      <b-card>
        <b-row>
          <b-col md="2" style="display:inherit">
            <b-form-checkbox
                v-model="isAllSelected"
                v-if="_orders.length>0"
                class=" custom-control-secondary"
                @change="selectAllOrder"
            />
            ({{ _selectedOrders.length }})
            <b-dropdown variant="link" no-caret v-if="_selectedOrders.length">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
              </template>
              <b-dropdown-item @click="addToCardAll">
                <feather-icon icon="ShoppingCartIcon"/>
                <span class="align-middle ml-50">Sepete Ekle</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="TrashIcon"/>
                <span class="align-middle ml-50">Sil</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>

          <b-col md="4">
            Ürün Detayı
          </b-col>

          <b-col md="2">
            Müşteri Detayları
          </b-col>

          <b-col md="2">
            Kargo Detayları
          </b-col>
          <b-col md="2">
            Yönetim
          </b-col>
        </b-row>
      </b-card>
      <OrderListOrder v-for="order in _orders" :key="order.id" :order="order"/>
    </section>
    <OrderProductList v-else/>
    <section id="search-section">
      <b-card class="mt-1 mb-0">
        <Pagination :pagination="_pagination" @pageClick="pageClick"/>
      </b-card>
    </section>

    <OrderPrint/>
    <OrderPrintHb/>
    <OrderListTimeLine/>
    <ProductEdit/>
    <ProductImages2/>
    <OrderProductStatistics/>
    <EditProductBarcodes/>
    <OrderProductSupplierProductSelecet/>
    <StoreProductDetail/>
    <DelSupplierProductStok/>
    <OrderPrintLabel v-if="orders.length>0" :orders="orders" style="display: none"/>
    <!-- /Listeleme Bölümü -->
  </section>
</template>

<script>
import OrderListSearch from '@/views/order/OrderListSearch'
import OrderListOrder from '@/views/order/list/OrderListOrder'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import {mapGetters} from 'vuex'
import Pagination from '@/components/Pagination'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import OrderPrint from '@/views/order/OrderPrint'
import OrderPrintHb from '@/views/order/OrderPrintHb'
import OrderListTimeLine from '@/views/order/list/OrderListTimeLine'
import OrderProductList from '@/views/order/list/OrderProductList'
import ProductEdit from '@/views/product/Edit'
import ProductImages2 from '@/components/ProductImages2'
import OrderBasket from '@/views/order/OrderBasket'
import OrderProductStatistics from '@/views/order/list/OrderProductStatistics'
import OrderFilter from '@/views/order/list/OrderFilter'
import EditProductBarcodes from '@/views/product/EditProductBarcodes'
import OrderProductSupplierProductSelecet from "@/views/order/list/OrderProductSupplierProductSelecet.vue";
import StoreProductDetail from "@/views/product/StoreProductDetail.vue";
import DelSupplierProductStok from "@/views/product/product/DelSupplierProductStok.vue";
import OrderPrintLabel from "@/views/order/list/OrderPrintLabel.vue";

export default {
  name: 'OrderListHome',
  components: {
    ProductEdit,
    OrderListSearch,
    OrderListOrder,
    Pagination,
    OrderPrint,
    OrderPrintHb,
    OrderProductList,
    OrderListTimeLine,
    ProductImages2,
    OrderBasket,
    OrderProductStatistics,
    OrderFilter,
    BCardActions,
    EditProductBarcodes,
    OrderProductSupplierProductSelecet,
    StoreProductDetail,
    DelSupplierProductStok,
    OrderPrintLabel
  },
  data: () => ({
    selected: false,
    orders: [],
  }),
  computed: {
    ...mapGetters('order', ['_orders', '_search', '_pagination', '_selectedOrders', '_isOrderProductList']),
    ...mapGetters('orderBasket', ['_selected']),
    isAllSelected: {
      get() {
        if (this._orders.length <= this._selectedOrders.length) {
          return true
        }
        return false
      },
      set() {

      }
    }
  },
  methods: {
    pageClick(page) {
      this.show = true
      this._search.page = page
      this.$store.dispatch('order/getAll')
          .then(res => {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    },
    selectAllOrder(event) {
      let selected = []
      if (event) {
        this._orders.forEach(order => {
          selected.push(order.id)
        })
      }
      this.$store.state.order.selectedOrders = selected
    },
    addToCardAll() {
      let isFalse = false
      this._orders.forEach(order => {
        if (order.order_status_id != 13) isFalse = true
      })
      if (isFalse) {
        this.$swal({
          icon: 'error',
          title: 'Sepete Atma Yapılamıyor',
          text: 'Sadece Toplanacak olanlar sepete atılabilir',
          footer: 'Toplanacak haricindekileri seçiliden çıkartmalısın!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',

          },
          buttonsStyling: false,
        })
      } else {
        this.$swal({
          icon: 'info',
          title: 'Sepete Atmak İstediğinden Emin misin?',
          text: 'Tüm siparişler sepete eklenecekler',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Ekle',
          cancelButtonText: 'İptal',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          preConfirm: data => {
            if (!data) return null
            this._orders.forEach(order => {
              if (this._selectedOrders.includes(order.id)) {
                this.addToCard(order)
              }
            })
            return true
          }
        })
            .then(result => {
              if (result.value) {
                this.$swal({
                  title: 'İşlem Bitti',
                  customClass: {confirmButton: 'btn btn-primary'},
                })
              }
            })
      }

    },
    addToCard(order) {

      let basket
      if (this._selected == '') {
        basket = prompt('Lütfen sepet adı giriniz')
        basket = basket.trim()
        if (basket == '' || basket == null) {
          alert('sepet adı geçersiz')
          return
        }
        this.$store.commit('orderBasket/setSelected', basket)
      }
      basket = this._selected

      if (order.tracking.cargo_company_id == 1) {
        basket = basket + '-AK'
      } else if (order.tracking.cargo_company_id == 2) {
        basket = basket + '-SK'
      } else if (order.tracking.cargo_company_id == 3) {
        basket = basket + '-YK'
      } else if (order.tracking.cargo_company_id == 4) {
        basket = basket + '-HBJ'
      } else if (order.tracking.cargo_company_id == 5) {
        basket = basket + '-TEX'
      } else if (order.tracking.cargo_company_id == 7) {
        basket = basket + '-KG'
      }

      let data = {
        id: order.id,
        invoice_basket: {
          order_id: order.id,
          basket_name: basket,
        }
      }

      this.$store.dispatch('order/orderAddBasket', data)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.title,
                icon: 'ArrowUpIcon'
              }
            })
          })
    },
    getAll(pageName) {
      this.$store.commit('order/setFilterBarcode', '')
      this.$store.commit('order/setSearchNull')
      this.$store.commit('order/setOrders', [])
      this.$store.state.order.pagination = null

      if (pageName == 'invoice') {
        this._search.isBasket = true
        this._search.limit = 10000
      } else if (pageName == 'preparing') {
        this._search.orderStatus = [1, 2, 3]
        this._search.orderBy = 'order_last_delivery_date'
        this._search.sorting = 'ASC'
        this._search.limit = 10000
      } else if (pageName == 'collet') {
        this._search.orderStatus = [13]
        this._search.orderBy = 'order_last_delivery_date'
        this._search.sorting = 'ASC'
        this._search.limit = 10000
      } else {
        this._search.isBasket = false
        this._search.limit = 10
      }

      this._search.page = 1
    }
  },
  watch: {
    '$route.meta'(to) {
      this.getAll(to.pageName)
    }
  },
  created() {
    this.$store.dispatch('orderStatus/getAll')
    this.$store.dispatch('store/stores')
    this.$store.dispatch('cargoCompany/getAll')
    this.$store.dispatch('productBrand/getAll')
    this.getAll(this.$route.meta.pageName)
  },
  mounted() {
    this.$root.$on('order-print-label', orders => {
      this.orders = orders
    })
  },
}
</script>

<style scoped>
.card {
  margin: 5px;
}

.card-body {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
