<template>
  <b-card-actions class="mt-0 mb-0" :title="titleName" action-collapse>
    <b-overlay :show="show" rounded="sm">
      <b-form @submit.prevent :class="bgcolor">
        <b-row>
          <b-col md="7">
            <b-row class="m-0 p-0">
              <!-- Arama Kutucukları -->
              <b-col md="2" class="m-0 p-0">
                <b-form-input type="number" size="sm" v-model="_search.id" placeholder="Sipariş ID"/>
                <b-form-input type="text" size="sm" v-model="_search.order_number"
                              placeholder="Sipariş No"/>
                <b-form-input type="text" size="sm" v-model="_search.package_number"
                              placeholder="Paket No"/>
              </b-col>

              <b-col md="2" class="m-0 p-0">
                <b-form-input type="number" size="sm" v-model="_search.product.id"
                              placeholder="Ürün ID"/>
                <b-form-input type="text" size="sm" v-model="_search.product.barcode"
                              placeholder="Ürün Barkod"/>
                <b-form-input type="text" size="sm" v-model="_search.product.product_name"
                              placeholder="Ürün Adı"/>
              </b-col>

              <b-col md="2" class="m-0 p-0">
                <b-form-input type="text" size="sm" v-model="_search.tracking.tracking_number"
                              placeholder="Takip No"/>
                <vue-autosuggest
                    :suggestions="filteredBrands"
                    :limit="limit"
                    :input-props="{id:'autosuggest__input',class:'form-control form-control-sm', placeholder:'Marka Seçiniz!'}"
                    @input="onInputChange"
                    @selected="selectHandler"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                  </template>
                </vue-autosuggest>

                <b-badge v-for="(brand,key) in _search.brands" :key="brand.id"
                         @click="deleteBrand(key)"
                >{{ brand.name }}
                </b-badge>

              </b-col>

              <b-col md="2" class="m-0 p-0">
                <b-form-input type="text" size="sm" v-model="_search.customer_name"
                              placeholder="Müşteri Adı"/>
                <b-form-input type="text" size="sm" v-model="_search.customer_mail"
                              placeholder="Müşteri Mail"/>
              </b-col>


              <b-col md="2" class="m-0 p-0">
                <v-select
                    v-model="_search.limit"
                    :options="[1,5,10,20,50,100,250,500,1000,2500,10000]"
                    class="select-size-sm"
                    placeholer="Limit"
                />
                <v-select
                    v-model="_search.sorting"
                    label="key"
                    :options="sortingList"
                    :reduce="item => item.val"
                    class="select-size-sm"
                    placeholder="Sıralama"
                />
                <v-select
                    v-model="_search.orderBy"
                    label="key"
                    :options="orderByList"
                    :reduce="item => item.val"
                    class="select-size-sm"
                    placeholder="Sır. Türü"
                />
              </b-col>
              <b-col md="2" class="m-0 p-0">
                <flat-pickr
                    v-model="_search.startDate"
                    id="bas_tarihi"
                    class="form-control"
                    :config="startDate"
                    style="height: 30px;"
                    placeholder="Baş. Tarh."
                />
                <flat-pickr
                    v-model="_search.endDate"
                    id="bit_tarihi"
                    class="form-control"
                    :config="endDate"
                    style="height: 30px;"
                    placeholder="Bit. Tarh."
                />

                <v-select
                    v-model="_search.dateType"
                    label="key"
                    :options="orderByList"
                    :reduce="item => item.val"
                    class="select-size-sm"
                    placeholder="Tarih Tipi"
                />

              </b-col>


              <!-- /Arama Kutucukları -->
            </b-row>
          </b-col>
          <b-col md="4" class="m-0 p-0">
            <b-row>
              <b-col md="3" class="m-0 p-0">
                <v-select
                    v-model="_search.orderStatus"
                    label="status"
                    multiple
                    :options="_orderStatuses"
                    :reduce="status => status.id"
                    class="select-size-sm"
                    placeholder="Sip. Durumu"
                    :closeOnSelect="false"
                />
              </b-col>
              <b-col md="3" class="m-0 p-0">
                <v-select
                    v-model="_search.suppliers"
                    label="name"
                    multiple
                    :options="_suppliers"
                    :reduce="item => item.id"
                    class="select-size-sm"
                    placeholder="Tedarikçi"
                    :closeOnSelect="false"
                >
                </v-select>
              </b-col>
              <b-col md="3" class="m-0 p-0">
                <v-select
                    v-model="_search.stores"
                    label="store_name"
                    multiple
                    :options="_stores"
                    :reduce="store => store.id"
                    class="select-size-sm"
                    placeholder="Pazaryeri"
                    :closeOnSelect="false"
                />
              </b-col>
              <b-col md="3" class="m-0 p-0">
                <v-select
                    v-model="_search.tracking.companies"
                    label="name"
                    multiple
                    :options="_companies"
                    :reduce="item => item.id"
                    class="select-size-sm"
                    placeholder="Kargo"
                    :closeOnSelect="false"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="7">
                <b-row>
                  <b-col class="demo-inline-spacing" style="margin-top: 0px">
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_search.isSingleMulti"
                        plain
                        name="isSingleMulti"
                        value="all"
                    > Tümü
                    </b-form-radio>
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_search.isSingleMulti"
                        plain
                        name="isSingleMulti"
                        value="single"
                    > Tekli
                    </b-form-radio>
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_search.isSingleMulti"
                        plain
                        name="isSingleMulti"
                        value="multi"
                    > Çoklu
                    </b-form-radio>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col style="padding-left: 30px">
                    <b-form-group>
                      <b-form-checkbox-group
                          size="sm"
                          v-model="_search.channels"
                          :options="channels"
                          class="demo-inline-spacing"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="5">
                <b-row>
                  <b-form-group>
                    <b-form-checkbox
                        v-model="_search.isStocks"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                    >Stoklu
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="_search.exclusion"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                    >Hariçler
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-if="userData.roles.includes('manager')"
                        v-model="_search.accountings"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                    >Muhasebe
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-if="userData.roles.includes('manager')"
                        v-model="_search.profit"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                    >Kar Zarar
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="_search.brandNull"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                    >Markasızlar
                    </b-form-checkbox>

                    <b-form-checkbox
                        v-model="$store.state.order.isMpImage"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                    >PY Resim
                    </b-form-checkbox>

                    <b-form-checkbox
                        v-model="_search.isFollow"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                    >Takiptekiler
                    </b-form-checkbox>

                    <b-form-checkbox
                        v-model="_search.micro"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                    >Mikro İhr.
                    </b-form-checkbox>

                  </b-form-group>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="1">
            <!-- Arama Butonları -->
            <b-row>
              <b-col>
                <b-button-group size="sm">
                  <!-- Butonlar Buraya Gelecek -->
                  <b-button variant="primary" type="submit" @click="getAll" title="Getir">
                    <feather-icon icon="FilterIcon"></feather-icon>
                  </b-button>
                  <b-button variant="danger" @click="clearSearch" title="Temizle">
                    <feather-icon icon="XCircleIcon"></feather-icon>
                  </b-button>


                </b-button-group>

                <b-button-group size="sm">
                  <b-button variant="warning" @click="getProductPrice" title="Temizle">
                    <feather-icon icon="DollarSignIcon"></feather-icon>
                  </b-button>
                  <b-button variant="info" @click="getToExcel" title="Excele Çıkar"
                            :disabled="isGetToExcelBtn"
                  >
                    <feather-icon icon="FileIcon"></feather-icon>
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1">
                <b-checkbox v-model="_search.is_commercial">Kurumsal</b-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1">
                <b-checkbox v-model="_search.is_not_invoiced">Faturasız</b-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1">
                <b-checkbox v-model="_search.product.is_not_tax">KDV siz</b-checkbox>
              </b-col>
            </b-row>


            <!-- /Arama Butonları -->
          </b-col>
        </b-row>
        <Pagination :pagination="_pagination" @pageClick="pageClick"/>
      </b-form>
    </b-overlay>
  </b-card-actions>
</template>

<script>
import {mapGetters} from 'vuex'
import vSelect from 'vue-select'
import Pagination from '@/components/Pagination'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import flatPickr from 'vue-flatpickr-component'
import {VueAutosuggest} from 'vue-autosuggest'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import axios from "@/libs/axios";

export default {
  name: 'OrderListSearch',
  components: {
    vSelect,
    Pagination,
    flatPickr,
    VueAutosuggest,
    BCardActions,
  },
  props: {
    bgcolor: {
      type: String,
    },
  },
  data: () => ({
    isGetToExcelBtn: false,
    titleName: 'Siparişler',
    channels: ['MFN', 'AFN', 'ATE'],
    filteredBrands: [],
    limit: 20,
    userData: JSON.parse(localStorage.getItem('userData')),
    dateNtim: null,
    show: false,
    orderByList: [
      {
        key: 'Sipariş Tarihi',
        val: 'order_created_date'
      },
      {
        key: 'Teslim Tarihi',
        val: 'order_last_delivery_date'
      },
      {
        key: 'Ekleme Tarihi',
        val: 'created_at'
      },
      {
        key: 'Güncelleme Tarihi',
        val: 'updated_at'
      },
    ],
    sortingList: [
      {
        key: 'A-Z',
        val: 'ASC'
      },
      {
        key: 'Z-A',
        val: 'DESC'
      },
    ],
    startDate: {
      enableTime: true,
      defaultHour: 0,
      defaultMinute: 0,
      time_24hr: true,
      dateFormat: 'Y-m-d H:i',
      locale: {firstDayOfWeek: 1},
      weekNumbers: true,
    },
    endDate: {
      enableTime: true,
      defaultHour: 23,
      defaultMinute: 59,
      time_24hr: true,
      dateFormat: 'Y-m-d H:i',
      locale: {firstDayOfWeek: 1},
    }
  }),
  computed: {
    ...mapGetters('order', ['_orders', '_search', '_pagination', '_selectedOrders', '_isOrderProductList', '_filterBasket']),
    ...mapGetters('orderStatus', ['_orderStatuses']),
    ...mapGetters('store', ['_stores']),
    ...mapGetters('supplier', ['_suppliers']),
    ...mapGetters('orderBasket', ['_baskets', '_selected']),
    ...mapGetters('cargoCompany', ['_companies']),
    ...mapGetters('productBrand', ['_brands']),
    isAfn() {
      if (['amin'].includes(this.userData.role)) {
        return true
      }
      return false
    }
  },
  watch: {
    '$route.meta'(to) {
      if (to.pageName == 'invoice') {
        this.titleName = 'Fatura Sepeti'
      } else if (to.pageName == 'preparing') {
        this.titleName = 'Hazırlanıyor'
      } else if (to.pageName == 'collet') {
        this.titleName = 'Toplanacaklar'
      } else {
        this.titleName = 'Siparişler'
      }
    }
  },
  methods: {
    getToExcel() {
      this.isGetToExcelBtn = true;
      axios.post('/order/getexcel', this._search, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = "siparisler"
            link.click()
            URL.revokeObjectURL(link.href)
            this.isGetToExcelBtn = false;
          });
    },
    deleteBrand(key) {
      this.$store.commit('order/deleteBrand', key)
    },
    onInputChange(text) {
      if (text === '' || text === undefined) return
      const data = this._brands.filter(brand => brand.name.toLowerCase()
          .indexOf(text.toLowerCase()) > -1)
          .splice(0, this.limit)
      this.filteredBrands = [{data}]
    },
    selectHandler(option) {
      this.$store.state.order.search.brands.push(option.item)
    },
    getAll() {
      this.$store.commit('order/setFilterBarcode', '')
      this.show = true
      this._search.page = 1
      this.$store.dispatch('order/getAll')
          .then(res => {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    },
    clearSearch() {
      this.$store.commit('order/setSearchNull')
    },
    pageClick(page) {
      this.show = true
      this._search.page = page
      this.$store.dispatch('order/getAll')
          .then(res => {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    },
    getProductPrice() {
      this.$store.dispatch('product/getProductPrices')
    },
  },
}
</script>


<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.custom-checkbox.b-custom-control-sm {
  padding-right: 10px;
  padding-left: 0px;
}
</style>
