import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('supplier', ["_suppliers"]),
    },
    methods: {
        supplier_name(id) {
            let item = this._suppliers.find(item => item.id == id)
            if (!item) return 'Boş'
            return item.name
        }
    }
}
