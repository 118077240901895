<template>
  <b-modal
      id="order-product-statistics-modal"
      ref="order-product-statistics-modal"
      title="Satış İstatistikleri"
      ok-only
      size="xl"
      @show="showModal"
      @shown="shownModal"
      @hide="hideModal"
  >
    <b-overlay :show="show" rounded="sm">

      <table class="table table-hover">
        <thead>
        <tr>
          <th scope="col">Firma</th>
          <th scope="col">Bilgi</th>
          <th scope="col">Günlük</th>
          <th scope="col">3 Günlük</th>
          <th scope="col">Haftalık</th>
          <th scope="col">Aylık</th>
          <th scope="col">3 Aylık</th>
          <th scope="col">6 Aylık</th>
          <th scope="col">Yıllık</th>
        </tr>
        </thead>
        <tbody>
        <tr class="table-primary">
          <td><b>Toplam</b></td>
          <td>
            Sipariş / Adet<br>
            <span v-if="user.role=='admin'">Birim / Ciro</span>
          </td>
          <td v-for="(item2,index) in sums" :key="index">
            <div v-if="item2.quantityCount">
              {{ item2.quantityCount }} / {{ item2.quantitySum }} <br>
              <span v-if="user.role=='admin'">{{
                  (item2.priceSum / item2.quantitySum).toFixed(2)
                }} / {{ item2.priceSum.toFixed(2) }}</span>
            </div>
          </td>
        </tr>
        <tr v-for="(item,index) in statistics" :key="index">
          <td><b>{{ item.name }}</b></td>
          <td>
            Sipariş / Adet<br>
            <span v-if="user.role=='admin'">Birim / Ciro</span>
          </td>
          <td v-for="(item2,index2) in item.data" :key="index2">
            <div v-if="item2.quantityCount">
              {{ item2.quantityCount }} / {{ item2.quantitySum }} <br>
              <span v-if="user.role=='admin'">{{
                  (item2.priceSum / item2.quantitySum).toFixed(2)
                }} / {{ item2.priceSum.toFixed(2) }}</span>
            </div>
            <div v-else>-</div>


          </td>
        </tr>
        </tbody>
      </table>
    </b-overlay>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "@/libs/axios";
import storeMixins from "@/mixins/storeMixins";

export default {
  name: "OrderProductStatistics",
  mixins: [storeMixins],
  data: () => ({
    show: true,
    statistics: [],
    sums: [],
    user: JSON.parse(localStorage.getItem('userData')),
  }),
  computed: {
    ...mapGetters("order", ["_orderProductStatisticsProductId"])
  },
  methods: {
    showModal() {
      this.show = true;
      this.statistics = [];
      this.sums = [];
      axios.get('/statistics/order/product/' + this._orderProductStatisticsProductId)
          .then(res => {
            this.statistics = res.data.data;
            this.sums = res.data.sums;
            this.show = false;
          })
          .catch(() => {
            this.show = false;
          })
    },
    shownModal() {

    },
    hideModal() {

    }
  }

}
</script>

<style scoped>

</style>
