import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('product', ['_productScanPrices']),
  },
  methods: {
    getPrices(productId) {
      return this._productScanPrices.find(product => product.product_id == productId)
    },
  },
}
