import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('users', ["_users"]),
    },
    methods: {
        user_name(id) {
            let item = this._users.find(item => item.id == id)
            if (!item) return 'İsimsiz'
            return item.short_name
        }
    }
}
