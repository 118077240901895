<template>
  <table class="table table-sm" style="font-size:0.8em;font-weight:bold;">
    <thead>
    <tr class="table-secondary">
      <td style="text-align:center">Detay</td>
      <td style="text-align:center">Fiyat</td>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>
        Satış
        <span style="float:right;">:</span>
      </td>
      <td class="numberTd">{{ accounting.actual_selling_price_net }}</td>
    </tr>
    <tr>
      <td>Kom. <span style="float:right;">:</span></td>
      <td class="numberTd">(%{{
          ((accounting.actual_commission / accounting.actual_selling_price_net) * 100).toFixed(1)
        }})
        {{ accounting.actual_commission.toFixed(2) }}
      </td>
      <td></td>
    </tr>
    <tr>
      <td>Kargo <span style="float:right;">:</span></td>
      <td class="numberTd">{{ accounting.actual_cargo1 }}</td>
    </tr>
    <tr class="table-secondary">
      <td>Stş. Kal. <span style="float:right;">:</span></td>
      <td class="numberTd">{{ fromSalesPrice }}</td>
    </tr>
    <tr class="table-warning">
      <td>Hsp. Alış <span style="float:right;">:</span></td>
      <td class="numberTd">{{ calculatedPurchasePrice }}</td>
    </tr>
    <tr :class="result - calculatedPurchasePrice>0?'table-success':'table-danger'"
        v-if="calculatedPurchasePrice"
    >
      <td>Hsp.Stş. K/Z<span style="float:right;">:</span></td>
      <td class="numberTd">(%{{
          (((fromSalesPrice - calculatedPurchasePrice) / accounting.actual_selling_price_net) * 100).toFixed(1)
        }})
        {{ (fromSalesPrice - calculatedPurchasePrice).toFixed(2) }}
      </td>
    </tr>
    <tr :class="result - calculatedPurchasePrice>0?'table-success':'table-danger'" v-if="calculatedPurchasePrice">
      <td>Hsp.Alş. K/Z<span style="float:right;">:</span></td>
      <td class="numberTd">(%{{
          (((fromSalesPrice - calculatedPurchasePrice) / calculatedPurchasePrice) * 100).toFixed(1)
        }})
        {{ (fromSalesPrice - calculatedPurchasePrice).toFixed(2) }}
      </td>
    </tr>
    <tr class="table-info">
      <td>Seç. Alış :</td>
      <td class="numberTd">{{ selectedPurchasePrice }}</td>
    </tr>
    <tr v-if="selectedPurchasePrice"
        :class="fromSalesPrice - selectedPurchasePrice>0?'table-success':'table-danger'"
    >
      <td>Seç.Stş. K/Z<span style="float:right;">:</span></td>
      <td class="numberTd">(%{{
          (((fromSalesPrice - selectedPurchasePrice) / accounting.actual_selling_price_net) * 100).toFixed(2)
        }}) {{ (fromSalesPrice - selectedPurchasePrice).toFixed(2) }}
      </td>
    </tr>

    <tr v-if="selectedPurchasePrice"
        :class="fromSalesPrice - selectedPurchasePrice>0?'table-success':'table-danger'"
    >
      <td>Seç.Alş. K/Z<span style="float:right;">:</span></td>
      <td class="numberTd">(%{{
          (((fromSalesPrice - selectedPurchasePrice) / selectedPurchasePrice) * 100).toFixed(2)
        }}) {{ (fromSalesPrice - selectedPurchasePrice).toFixed(2) }}
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'OrderProductAccounting',
  props: {
    product: {
      type: Object
    },
    accounting: {
      type: Object,
    },
  },
  computed: {
    fromSalesPrice() {
      return (this.accounting.actual_selling_price_net - (this.accounting.actual_commission + this.accounting.actual_cargo1)).toFixed(2)
    },
    result() {
      return (this.accounting.actual_selling_price_net - (this.accounting.actual_commission + this.accounting.actual_cargo1)).toFixed(2)
    },
    calculatedPurchasePrice() {
      return this.accounting.calculated_purchase_price
    },
    selectedPurchasePrice() {
      return this.accounting.selected_purchase_price
    },
  },
}
</script>

<style scoped>
.numberTd {
  text-align: right;
}
</style>
