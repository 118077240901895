<template>
  <b-modal ref="product-store-detail"
           id="product-store-detail"
           hide-footer
           size="lg"
           title="Ürün Mağaza Detayları">
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col>Ürün Bilgileri</b-col>
      </b-row>


      <div v-if="product && product.az_products">
        <b-row
            v-for="azProduct in product.az_products"
            :key="azProduct.id"
            class="border border-info rounded"
        >
          <b-col>
            <b-row>
              <b-col> Amazon</b-col>
              <b-col> ID: {{ azProduct.id }}</b-col>
              <b-col> ASIN: {{ azProduct.merchant_sku }}</b-col>
            </b-row>
            <b-row
                v-for="azStoreProduct in azProduct.az_store_products"
                :key="azStoreProduct.id"
                class="border border-info border-bottom"
            >
              <b-col> {{ azStoreProduct.store.store_name }}</b-col>
              <b-col>
                {{ azStoreProduct.stock }} <br>
                {{ azStoreProduct.send_stock }}
              </b-col>

              <b-col>
                {{ azStoreProduct.calculated_price }} <br>
                {{ azStoreProduct.send_price }}
              </b-col>
              <b-col>
                {{ azStoreProduct.min_price }} <br>
                {{ azStoreProduct.max_price }}
              </b-col>
              <b-col>
                <viewDate :date="azStoreProduct.updated_at"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <div v-if="product && product.hb_products" class="mt-1">
        <b-row
            v-for="hbProduct in product.hb_products"
            :key="hbProduct.id"
            class="border border-warning rounded"
        >
          <b-col>
            <b-row>
              <b-col> Hepsiburada</b-col>
              <b-col> ID: {{ hbProduct.id }}</b-col>
              <b-col> HbSKU: {{ hbProduct.merchant_sku }}</b-col>
            </b-row>

            <b-row
                v-for="hbStoreProduct in hbProduct.hb_store_products"
                :key="hbStoreProduct.id"
                class="border border-warning border-bottom"
            >
              <b-col> {{ hbStoreProduct.store.store_name }}</b-col>

              <b-col>
                {{ hbStoreProduct.stock }} <br>
                {{ hbStoreProduct.send_stock }}
              </b-col>

              <b-col>
                {{ hbStoreProduct.calculated_price }} <br>
                {{ hbStoreProduct.send_price }}
              </b-col>

              <b-col>
                {{ hbStoreProduct.min_price }} <br>
                {{ hbStoreProduct.max_price }}
              </b-col>

              <b-col>
                <viewDate :date="hbStoreProduct.updated_at"/>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
      </div>


      <div v-if="product && product.ty_products" class="mt-1">
        <b-row
            v-for="tyProduct in product.ty_products"
            :key="tyProduct.id"
            class="border border-primary rounded"
        >
          <b-col>
            <b-row>
              <b-col> Trendyol</b-col>
              <b-col> ID: {{ tyProduct.id }}</b-col>
              <b-col> Barkod: {{ tyProduct.merchant_sku }}</b-col>
            </b-row>

            <b-row
                v-for="tyStoreProduct in tyProduct.ty_store_products"
                :key="tyStoreProduct.id"
                class="border border-primary border-bottom"
            >
              <b-col> {{ tyStoreProduct.store.store_name }}</b-col>

              <b-col>
                {{ tyStoreProduct.stock }} <br>
                {{ tyStoreProduct.send_stock }}
              </b-col>

              <b-col>
                {{ tyStoreProduct.calculated_price }} <br>
                {{ tyStoreProduct.send_price }}
              </b-col>

              <b-col>
                {{ tyStoreProduct.min_price }} <br>
                {{ tyStoreProduct.max_price }}
              </b-col>

              <b-col>
                <viewDate :date="tyStoreProduct.updated_at"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-overlay>

  </b-modal>
</template>

<script>
import axios from "@/libs/axios";
import viewDate from "@/views/common/ViewDate.vue";

export default {
  name: "StoreProductDetail",
  components: {viewDate},
  data: () => ({
    show: true,
    product: null,
  }),
  mounted() {
    this.$root.$on('productStoreDetailProductId', (productId) => {
      this.show = true;
      axios.get(`product/store/${productId}/detail`)
          .then(res => {
            this.product = res.data.content;
            this.show = false;
          })
    });
  }
}
</script>

<style scoped>

</style>
