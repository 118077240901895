import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {ToastificationContent},
    methods: {
        doCopy(data) {
            var orderIdField = document.createElement("textarea");
            orderIdField.innerText = data;
            document.body.appendChild(orderIdField);
            orderIdField.select();
            document.execCommand("copy");
            this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: data,
                        icon: 'CopyIcon',
                        text: 'Kopyalandı',
                        variant: 'info',
                    },
                },
                {position: 'top-center'});
            orderIdField.remove();
        }
    }
}
