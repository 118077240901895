<template>
  <b-modal
      id="order-print-hb-modal"
      ref="order-print-hb-modal"
      hide-footer
      hide-header
      size="lg"
      @hidden="handHiden"
      @shown="handShown"
  >
    <b-button class="pull-right" variant="success" @click="print">Yazdır</b-button>
    <div id="printArea">
      <div class="book" v-if="seletedOrders.includes(order.id)" v-for="(order,index) in _orders" :key="order.id">
        <div class="page">
          <div class="subpage3">
            <div class="hb-image-container">
              <img :src="'data:image/jpeg;base64, '+label(order.id).png_code"/>
            </div>
            <span class="siparisIdBilgisi2">{{ order.id }} / {{ order.order_number }}</span>
          </div>
        </div>
      </div>
    </div>
    <b-button class="pull-right" variant="success" @click="print">Yazdır</b-button>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import ViewDate from "@/views/common/ViewDate";
import VueBarcode from 'vue-barcode';

export default {
  name: "OrderPrintHb",
  components: {ViewDate, VueBarcode},
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData')),
  }),
  computed: {
    ...mapGetters("order", ["_selectedOrder", "_selectedOrders", "_orders"]),
    ...mapGetters('orderCommonLabel', ["_labels"]),
    seletedOrders() {
      if (this._selectedOrder) {
        return [this._selectedOrder];
      }
      return this._selectedOrders;
    }
  },
  methods: {
    label(order_id) {
      let item = this._labels.find(item => item.order_id == order_id)
      if (!item) return {}
      return item
    },
    handHiden() {
      this.$store.commit('order/setSelectedOrder', "");
    },
    async handShown() {
      await this.$htmlToPaper('printArea');
    },
    async print() {
      await this.$htmlToPaper('printArea');
    }
  }
}
</script>

<style scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
