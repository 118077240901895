<template>
  <tr>
    <td v-if="editStatus">{{ category.id }}</td>
    <td>{{ category.name }}</td>
    <td v-if="editStatus">{{ category.parent_id }}</td>
    <td v-if="editStatus">{{ category.status }}</td>
    <td v-if="editStatus">
      <b-button-group>
        <b-button
            variant="warning"
            size="sm"
            @click="showModal"
        >
          Düzenle
        </b-button>
        <b-button
            variant="danger"
            size="sm"
            @click="destroy"
        >
          Sil
        </b-button>
      </b-button-group>
    </td>
  </tr>
</template>

<script>
import {BButton, BButtonGroup} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: "Category",
  components: {BButton, BButtonGroup},
  directives: {
    Ripple,
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    editStatus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    destroy() {
      if (confirm("Kategoriyi silmek istediğinden emin misiniz?") == true) {
        this.$store.dispatch('productCategory/destroy', this.category.id)
            .then(res => {
              if (res) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.name + ' silindi',
                    variant: 'danger',
                    icon: 'DeleteIcon',
                  },
                })
              }
            })
      }
    },
    showModal() {
      this.$store.commit('productCategory/setSelected', this.category);
      this.$root.$emit('bv::show::modal', 'edit-category-modal', '#btnShow')
    }
  }
}
</script>

<style scoped>

</style>
