<template>
  <table class="table table-hover table-sm">
    <thead>
    <tr>
      <th>Mağaza</th>
      <th>Tedarikçi</th>
      <th>Adet</th>
      <th>Alış</th>
      <th>Termin</th>
    </tr>
    </thead>
    <tbody>
    <Product v-for="product in products" :key="product.id" :product="product">
    </Product>
    </tbody>
  </table>
</template>

<script>
import Product from "@/views/store-product/Product";

export default {
  name: "Products",
  components: {Product},
  props: {
    storeProdcuts: {
      type: Array,
      required: false
    }
  },
  computed: {
    products() {
      if (this.storeProdcuts) {
        return this.storeProdcuts
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>
