<template>
  <div class="mt-1">
    <b-dropdown
        v-b-tooltip.hover.top="invoice.message"
        size="sm"
        :variant="invoice.message?'danger':'success'">
      <template #button-content> {{ invoice.invoice_id }}</template>
      <b-dropdown-item size="sm" @click="showInvoice"> Göster</b-dropdown-item>
      <b-dropdown-item size="sm" @click="sendInvoiceLink">
        Pazaryerine Gönder
      </b-dropdown-item>
    </b-dropdown>
  </div>

</template>

<script>
import invoice from "@/store/invoice";
import axios from "@/libs/axios";

export default {
  name: "OrderListOrderInvoice",
  props: {
    invoice: {
      type: Object,
    }
  },
  data: () => ({
    isShowInvoice: false,
  }),
  methods: {
    showInvoice() {
      this.isShowInvoice = true;
      this.$store.dispatch('invoice/showInvoice', {
        documentId: this.invoice.document_id,
        action: 'GetOutboxInvoicePdf'
      })
          .then(res => {
            if (res.status == 'success') {
              this.$swal({
                width: '800px',
                html: '<embed src="data:application/pdf;base64,' + res.content + '" type="application/pdf" width="700" height="700" />'
              })
            }
            this.isShowInvoice = false
          })
    },
    sendInvoiceLink() {
      axios.get('/invoice/sendlink/' + this.invoice.document_id)
          .then(res => {
            if (res.data.status == 'success') {
              this.invoice.is_send_link = true;
              this.$swal({
                icon: 'success',
                title: 'Gönderildi...',
                text: 'Pazaryerinde gösterilmesi 1-2 dk alabilir!',
              })
            }
          })
    },
  }

}
</script>

<style scoped>

</style>
