import axios from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    methods: {
        recalculateInventory(productIds) {
            axios.post('/supplier/product/recalculateInventory', {ids: productIds})
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Yeniden Hesaplama',
                            icon: 'success',
                            text: 'İşlem başarıyla kuğruğa aktarıldı',
                            variant: 'info'
                        }
                    })
                })
        }
    }
}
