<template>
  <tr :class="tableClass">
    <td>
      ( {{ product.supplier_id }} )<span v-if="product.supplier">{{ product.supplier.name }}</span><br>
      <a :href="product.url" target="_blank">
        <feather-icon icon="ExternalLinkIcon"></feather-icon>
      </a>
    </td>
    <td>
      <b-img
          rounded
          class="d-inline-block mr-1 mb-1"
          width="120"
          :src="productImage"
      />

    </td>
    <td v-if="_search.selectedFilter.includes('product')">
      <b-img
          v-if="product.product"
          rounded
          class="d-inline-block mr-1 mb-1"
          width="120"
          :src="'https://api.kitapfirsatlari.com/'+product.product.images[0].url"
      />
    </td>
    <td>
      <b-overlay :show="editBarcodeShow">
        <feather-icon icon="EditIcon" v-b-tooltip.hover.top="'Barkod Düzenle'" @click="editBarcode"/>
        <b>{{ product.barcode }}</b>
      </b-overlay>
      <b v-if="_search.selectedFilter.includes('product') && product.product">
        <b-icon icon="arrow-up"/>
        {{ product.product.barcode }}<br>
      </b>

      <feather-icon icon="BookIcon"/>
      {{ product.name }}<br>
      {{ product.supplier_no }}
      <hr class="mt-0 mb-1">

      <b-badge>Stok Adet:
        <b-badge variant="info">{{ product.stock }}</b-badge>
      </b-badge>
      <b-badge class="ml-1">Termin:
        <b-badge variant="info">{{ product.deadline }}</b-badge>
      </b-badge>
      <b-badge class="ml-1">KDV:
        <b-badge variant="info">{{ product.vat }}</b-badge>
      </b-badge>
      <b-badge class="ml-1">ALIŞ:
        <b-badge variant="info">{{ product.price }}</b-badge>
      </b-badge>
      <b-badge class="ml-1">PSF:
        <b-badge variant="info">{{ product.market_price }}</b-badge>
      </b-badge>
      <b-badge v-if="product.currency" class="ml-1">
        <b-badge variant="info">{{ product.currency }}</b-badge>
      </b-badge>
      <hr>
      Ekleme: {{ new Date(product.created_at).toLocaleString("tr-TR", {dateStyle: "short", timeStyle: "short",}) }} <br>
      Güncelleme: {{ new Date(product.updated_at).toLocaleString("tr-TR", {dateStyle: "short", timeStyle: "short",}) }}
      <hr v-if="product.changes">
      <b-badge v-if="product.changes.length>5" size="sm" variant="info" @click="allShow=!allShow">
        Tümünü {{ allShow ? 'Gizle' : 'Göster' }}
        <b-badge>{{ product.changes.length }}</b-badge>
      </b-badge>
      <b-row v-if="index<5 || allShow" v-for="(change,index) in product.changes" :key="change.id">
        <b-col md="3" :class="change.old_barcode!=change.new_barcode?'bg-danger text-white':''">
          {{ change.old_barcode }}
        </b-col>
        <b-col md="3">{{ change.new_barcode }}</b-col>
        <b-col md="3" :class="change.old_name!=change.new_name?'bg-danger text-white':''">
          {{ change.old_name }}
        </b-col>
        <b-col md="3">{{ change.new_name }}</b-col>
      </b-row>
    </td>


    <td v-if="_search.selectedFilter.includes('publishers')">
      <b-list-group v-if="product.publishers" class="m-0">
        <b-list-group-item v-for="(item,index) in product.publishers" :key="index" class="p-0">
          {{ item.publisher_name }}
        </b-list-group-item>
      </b-list-group>
    </td>
    <td v-if="_search.selectedFilter.includes('categories')">
      <b-list-group v-if="product.categories">
        <b-list-group-item v-for="(category,index) in product.categories" :key="index" class="p-0">
          {{ category.name }}
        </b-list-group-item>
      </b-list-group>
    </td>

    <td v-if="_search.selectedFilter.includes('authors')">
      <b-list-group v-if="product.authors">
        <b-list-group-item v-for="(item,index) in product.authors" :key="index" class="p-0">
          {{ item.author_name }} {{ item.id }}
        </b-list-group-item>
      </b-list-group>
    </td>
    <td>
      <b-overlay :show="showProductStatus" rounded="sm">
        <b-badge class="mt-1" :variant="matching_status_color(product.status)">
          {{ matching_status(product.status) }}
        </b-badge>
        <br>
        <b-button-group size="sm" class="mt-1">
          <b-button @click="changeStatus(2)" size="sm" variant="success"> Onayla</b-button>
          <b-button @click="changeStatus(5)" size="sm" variant="warning"> Ertele</b-button>
          <b-button @click="changeStatus(4)" size="sm" variant="danger"> Reddet</b-button>
          <b-button @click="createNewProduct" size="sm" title="Yeni Ürün Olarak Aç" variant="primary"> Yeni Ürün
          </b-button>
        </b-button-group>
      </b-overlay>
      <hr>
      <b-overlay :show="showProductProductId" rounded="sm">
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-form-checkbox plain name="checkbox-input" v-model="productIdChange"/>
            </b-input-group-prepend>
            <b-form-input :disabled="!productIdChange" v-model="product.product_id" size="sm"
                          placeholder="Ürün no giriniz">
            </b-form-input>
            <b-input-group-append>
              <b-button size="sm" @click="newProductIdSave">
                <feather-icon icon="SaveIcon"></feather-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-overlay>

    </td>
  </tr>
</template>

<script>
import {BImg} from "bootstrap-vue";
import {mapGetters} from "vuex";
import matchingStatusMixins from "@/mixins/matchingStatusMixins";
import axios from "@/libs/axios";

export default {
  name: "Product",
  mixins: [matchingStatusMixins],
  components: {BImg},
  data: () => ({
    editBarcodeShow: false,
    showProductStatus: false,
    showProductProductId: false,
    productIdChange: false,
    allShow: false,
  }),
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("supplierProduct", ["_search"]),
    tableClass() {
      if (["1", "2"].indexOf(this.product.status)) {
        return '';
      }
      return 'table-warning';
    },
    productImage() {
      if (this.product.images) {
        if (this.product.images[0]) {
          if (this.product.images[0].url) {
            return this.product.images[0].url;
          }
        }
      }
      return '#'
    }
  },
  methods: {
    editBarcode() {
      let newBarcode = prompt("Please enter your name", this.product.barcode ?? "");
      if (newBarcode != null) {
        this.editBarcodeShow = true;
        this.$store.dispatch('supplierProduct/edit', {
          id: this.product.id,
          barcode: newBarcode,
        }).then(() => {
          this.editBarcodeShow = false;
        })
      }
    },
    changeStatus(status) {
      if (confirm("Ürün statüsünü değiştirmek istediğinizden emin misiniz?") == true) {
        this.showProductStatus = true;
        this.$store.dispatch("supplierProduct/edit", {id: this.product.id, status})
            .then(res => {
              if (res) {
                this.showProductStatus = false;
              }
            })
      }
    },
    newProductIdSave() {
      if (confirm("Ürün eşleşme no'sunu değiştirmek istediğinizden emin misiniz?") == true) {
        this.showProductProductId = true;
        this.$store.dispatch("supplierProduct/edit", {
          id: this.product.id,
          product_id: this.product.product_id
        }).then(res => {
          if (res) {
            this.showProductProductId = false;
          }
        })
      }
    },
    async createNewProduct() {
      let count = await this.isProduct();
      if (Number.isInteger(count)) {
        if (count > 1) {

        } else {

        }
      }
    },
    async isProduct() {
      return await axios.post('/products/all', {barcode: this.product.barcode})
          .then(res => {
            return res.data.length;
          })
          .catch(() => {
            alert('Bir Hata Var')
            return null;
          })
    }
  }
}
</script>

<style scoped>

</style>
