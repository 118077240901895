<template>
  <b-modal
      id="order-list-timeline-modal"
      ref="order-list-timeline-modal"
      title="Geçmiş Detayları"
      ok-only
      size="lg"
      @show="showModal"
      @shown="shownModal"
      @hide="hideModal"
  >
    <b-overlay
        :show="show"
        rounded="sm"
    >
      <b-container fluid>
        <app-timeline>
          <app-timeline-item
              v-for="line in timelines"
              :key="line.id"
              icon="DollarSignIcon"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>{{ line.message }}</h6>
              <small class="text-muted">
                <ViewDate :date="line.created_at" name=""></ViewDate>
              </small>
            </div>

            <div
                class="d-flex align-items-start flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-50">
              <h6 v-if="line.order_status_id">{{ order_status(line.order_status_id) }}</h6>
              <h6 v-else-if="line.supplier_id">{{ supplier_name(line.supplier_id) }} ({{ line.order_product_id }})</h6>
              <h6 v-else></h6>
              <b-badge pill variant="light-primary">
                {{ user_name(line.user_id) }}
              </b-badge>
            </div>
          </app-timeline-item>

        </app-timeline>
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import userMixins from "@/mixins/userMixins";
import supplierMixins from "@/mixins/supplierMixins";
import orderStatusMixins from "@/mixins/orderStatusMixins";
import axios from "@/libs/axios";
import {mapGetters} from "vuex";
import ViewDate from "@/views/common/ViewDate";

export default {
  name: "OrderListTimeLine",
  mixins: [userMixins, supplierMixins, orderStatusMixins],
  components: {
    AppTimeline,
    AppTimelineItem,
    ViewDate
  },
  data: () => ({
    show: false,
    timelines: []
  }),
  computed: {
    ...mapGetters("order", ["_timelineOrderId", "_timelineOrderProductId"])
  },
  methods: {
    showModal() {
      this.show = true;
    },
    shownModal() {
      if (this._timelineOrderId) {
        axios.get('/order/timeline/' + this._timelineOrderId)
            .then(res => {
              this.timelines = res.data;
              this.show = false;
            })
      } else if (this._timelineOrderProductId) {
        axios.get('/order/product/timeline/' + this._timelineOrderProductId)
            .then(res => {
              this.timelines = res.data;
              this.show = false;
            })
      }
    },
    hideModal() {
      this.timelines = [];
      this.$store.state.order.timelineOrderId = "";
      this.$store.state.order.timelineOrderProductId = "";
    }
  }
}
</script>

<style scoped>

</style>
