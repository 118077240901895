<template>
  <table class="table table-hover table-sm">
    <thead>
    <TableHead></TableHead>
    </thead>
    <tbody>
    <Product
        v-for="product in _products"
        :key="product.id"
        :product="product"
        :pagaName="pagaName"
    >
    </Product>
    </tbody>
  </table>
</template>

<script>
import TableHead from "@/views/product/TableHead";
import Product from "@/views/product/Product";
import {mapGetters} from "vuex";

export default {
  name: "Products",
  props: {
    pagaName: {
      type: String,
      default: null,
    }
  },
  components: {TableHead, Product},
  computed: {
    ...mapGetters('product', ["_products"]),
  }
}
</script>

<style scoped>

</style>
