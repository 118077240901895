<template>
  <b-modal
      id="order-print-modal"
      ref="order-print-modal"
      hide-footer
      hide-header
      size="lg"
      @hidden="handHiden"
      @shown="handShown"
  >
    <b-button class="pull-right" variant="success" @click="print">Yazdır</b-button>
    <div id="printArea">
      <div class="book" v-if="seletedOrders.includes(order.id)" v-for="(order,index) in _orders" :key="order.id">
        <div class="page">
          <div class="subpage">
            <div class="isimlik"><b>{{ order.customer_name }}</b></div>
            <div class="baslik">{{ order.store.store_name }} ({{ index + 1 }})</div>
            <hr>
            <div class="musteriAdres">
              {{ order.customer.shipment_full_address }}<br>
              {{ order.customer.shipment_phone_number }}
            </div>
            <hr>
            <div class="siparisBilgi">
              <span class="siparisIdBilgisi"><b>{{ order.id }} / {{ order.order_number }}</b></span> <br>
              {{
                new Date(order.order_created_date).toLocaleString("tr-TR", {
                  dateStyle: "short",
                  timeStyle: "short",
                })
              }} -
              {{
                new Date().toLocaleString("tr-TR", {
                  dateStyle: "short",
                  timeStyle: "short",
                })
              }}
              <span style="font-size: 7pt">{{ userData.short_name }}</span>
              <!--
              <br>
              <span v-if="order.tracking">{{ order.tracking.company.name }} - {{
                  order.tracking.tracking_number
                }}</span>
              -->
            </div>
            <hr>
            <div class="urunBilgi">
              <div v-for="(product) in order.products">
                {{ product.quantity }} Ad.
                <span v-if="product.product_name">{{ product.product_name.slice(0, 40) }}</span>
                <span v-else>...</span>
                <span v-if="product.come">- {{ product.come.id }}</span>
                <span v-if="!product.come && product.stock_card">- {{ product.stock_card.shelve_name }}</span><br>
              </div>
            </div>
            <hr>
            <div class="logolar">
              <div class="logolar1">
                <VueBarcode
                    :value="order.tracking.tracking_number"
                    format="CODE128"
                    height="30"
                    width="1"
                    marginTop="1"
                    marginBottom="1"
                    fontOptions="bold"
                    font-size="10"/>
              </div>
              <div class="logolar2">
                <img
                    :src="'/img/py_logo/'+order.store.logo"
                    :title="order.store.store_name"
                    height="35px"
                >

                <img
                    v-if="order.tracking"
                    :src="'/img/kargo_logo/'+order.tracking.company.image_url"
                    :title="order.tracking.company.name"
                    width="120px"
                >
              </div>
            </div>
            <div style="font-size: 8pt;display: block" v-if="order.invoice_basket">
              {{ order.invoice_basket.basket_name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-button class="pull-right" variant="success" @click="print">Yazdır</b-button>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import ViewDate from "@/views/common/ViewDate";
import VueBarcode from 'vue-barcode';

export default {
  name: "OrderPrint",
  components: {ViewDate, VueBarcode},
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData')),
  }),
  computed: {
    ...mapGetters("order", ["_selectedOrder", "_selectedOrders", "_orders"]),
    seletedOrders() {
      if (this._selectedOrder) {
        return [this._selectedOrder];
      }
      return this._selectedOrders;
    }
  },
  methods: {
    handHiden() {
      this.$store.commit('order/setSelectedOrder', "");
    },
    async handShown() {
      await this.$htmlToPaper('printArea');
    },
    async print() {
      await this.$htmlToPaper('printArea');
    }
  }
}
</script>

<style scoped>

</style>
