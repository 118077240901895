<template>
  <table class="table table-sm">
    <thead>
    <tr>
      <th scope="col">Detay</th>
      <th scope="col">Fiyat</th>
      <th scope="col">Kom.</th>
      <th scope="col">Kalan</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="acounting in accountings" :key="acounting.id">
      <td>{{ acounting.transaction_type }}</td>
      <template v-if="acounting.debt">
        <td v-if="acounting.debt">{{ acounting.debt.toFixed(2) * -1 }}</td>
        <td v-if="acounting.debt">{{ acounting.commission_amount }}</td>
        <td>{{ (acounting.debt - acounting.commission_amount) * -1 }}</td>
      </template>
      <template v-else>
        <td>{{ acounting.credit.toFixed(2) }}</td>
        <td>{{ acounting.commission_amount.toFixed(2) * -1 }}</td>
        <td>{{ (acounting.credit - acounting.commission_amount).toFixed(2) }}</td>
      </template>
    </tr>
    <tr class="table-dark">
      <td>Toplam:</td>
      <td>{{ total.total.toFixed(2) }}</td>
      <td>{{ total.commission.toFixed(2) }}</td>
      <td>{{ total.net.toFixed(2) }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  name: "Accounting",
  computed: {
    total() {
      let data = {
        total: 0,
        commission: 0,
        net: 0
      };

      this.accountings.forEach(accounting => {
        if (accounting.debt) {
          data.total -= accounting.debt;
          data.net -= accounting.debt;
          data.commission += accounting.commission_amount;
          data.net += accounting.commission_amount
        } else {
          data.total += accounting.credit;
          data.net += accounting.credit;
          data.commission -= accounting.commission_amount;
          data.net -= accounting.commission_amount;
        }
      })
      return data
    }
  },
  props: {
    accountings: {
      type: Array,
      default: []
    }
  },
}
</script>

<style scoped>

</style>
