<template>
  <b-card>
    <b-row>
      <b-col md="1">
        <b-button-group size="sm">
          <b-button
              :variant="_isOrderProductList?'outline-success':'success'"
              @click="setOrderProductList(false)"
          >
            <feather-icon icon="ShoppingBagIcon"/>
          </b-button>
          <b-button
              :variant="!_isOrderProductList?'outline-success':'success'"
              @click="setOrderProductList(true)"
          >
            <feather-icon icon="CoffeeIcon"/>
          </b-button>
        </b-button-group>
      </b-col>

      <b-col md="3">
        <b-button-group size="sm">
          <b-button
              variant="success"
              @click="setSelected"
              v-b-tooltip.hover.top="'Yeni Sepet Oluştur'"
          >
            <feather-icon icon="ShoppingCartIcon"/>
          </b-button>
          <b-button
              v-if="_selected"
              variant="warning"
          >
            {{ _selected }}
          </b-button>

          <b-button
              variant="secondary"
              @click="labelPrint"
              :disabled="_selectedOrders.length<1"
              v-b-tooltip.hover.top="'Etiket Yazdır'"
          >
            <feather-icon icon="FileIcon"/>
          </b-button>

          <b-button
              variant="warning"
              @click="getLabelAmz"
              :disabled="_selectedOrders.length<1"
              v-b-tooltip.hover.top="'Amazon Etiketleri Al'"
          >
            <feather-icon icon="FileIcon"/>
          </b-button>

          <b-button
              variant="secondary"
              @click="printLabelHb"
              :disabled="_selectedOrders.length<1"
              v-b-tooltip.hover.top="'Hepsijet Etiket Yazdır'"
          >
            <feather-icon icon="FileTextIcon"/>
          </b-button>
          <b-button
              variant="primary"
              @click="getOrderBasketNames"
          >
            <feather-icon icon="RefreshCwIcon"/>
          </b-button>
        </b-button-group>
      </b-col>

      <b-col md="8">
        <b-button-group size="sm">
          <b-button
              variant="outline-info"
              @click="selectedBasketTag=''"
          >Tümü
          </b-button>
          <b-button
              :variant="basketTag==selectedBasketTag?'info':'outline-info'"
              v-for="(basketTag,key1) in getBasketTags"
              :key="key1"
              @click="selectedBasketTag=basketTag"
              :disabled="basketTag==selectedBasketTag"
          >{{ basketTag }}
          </b-button>

        </b-button-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-button-group size="sm">
          <b-button
              :variant="_filterBasket == basket.basket_name?'primary':'outline-primary'"
              v-for="(basket,index) in _baskets2"
              @click="filterBasket(basket.basket_name)"
              :key="index"
              :disabled="!_search.isBasket"
          >
            {{ basket.basket_name }}
            <b-badge>{{ basket.total }}</b-badge>
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import OrderStatusMixins from "@/mixins/orderStatusMixins";
import axios from "@/libs/axios";

export default {
  name: "OrderBasket",
  mixins: [OrderStatusMixins],
  data: () => ({
    selectedBasketTag: "",
  }),
  computed: {
    ...mapGetters("order", ["_orders", "_search", "_pagination", "_selectedOrders", "_isOrderProductList", "_filterBasket", "_orderStatusCounts"]),
    ...mapGetters('orderBasket', ["_baskets", "_selected"]),
    getBasketTags() {
      let names = [];
      let cargos = [];
      this._baskets.forEach(basketname => {
        let basketNames = basketname.basket_name.split('-');
        const [name, cargo] = basketNames;
        if (name != undefined)
          if (!names.includes(name)) names.push(name)
        if (cargo != undefined)
          if (!cargos.includes(cargo)) cargos.push(cargo)
      })
      return names.concat(cargos);
    },
    _baskets2() {
      if (this.selectedBasketTag) {
        return this._baskets.filter(basket =>
            basket.basket_name.indexOf(this.selectedBasketTag) > -1
        )
      }
      return this._baskets
    }
  },
  methods: {
    setOrderProductList(status) {
      this.$store.commit('order/setOrderProductList', status)
    },
    setSelected() {
      let basket = prompt('Lütfen sepet adı giriniz');
      basket = basket.trim();
      if (basket == '' || basket == null) {
        alert('sepet adı geçersiz')
        return;
      }
      this.$store.commit('orderBasket/setSelected', basket)
    },
    getLabelAmz() {
      let order;
      let errorView = true;
      this._selectedOrders.forEach(selectOrder => {
        order = this._orders.find(item => item.id === selectOrder);
        if (order && order.store.marketplace_code === 'AZ') {
          if (!order.tracking.tracking_number || order.tracking.tracking_number.slice(0, 3) == 111) {
            axios.get(`/amazon/label/${order.id}`)
                .then(res => {
                  if (res.data.status === 'success') {
                    let finisOrder = this._orders.find(item2 => item2.id === res.data.content.order_id)
                    if (finisOrder) finisOrder.tracking = res.data.content;
                  } else {
                    if (errorView) {
                      this.$swal({
                        title: 'Kargo Etiketi Çekme Hatası',
                        icon: 'error',
                        text: `"${res.data.errors[0].code}"  ${res.data.errors[0].message}`
                      })
                    }
                    errorView = false;
                  }
                })
          }
        }
      })
    },
    labelPrint() {
      let orders = this._orders.filter(item => this._selectedOrders.includes(item.id));
      this.$root.$emit('order-print-label', orders)
    },
    printLabel($event) {
      axios.post('/timeline/label/print', {ids: this._selectedOrders})
      this.$root.$emit('bv::show::modal', 'order-print-modal', $event.target)
    },
    printLabelHb($event) {
      axios.post('/timeline/label/print', {ids: this._selectedOrders})
      this.$store.dispatch('orderCommonLabel/getAll', {ids: this._selectedOrders})
          .then(res => {
            if (res) {
              this.$root.$emit('bv::show::modal', 'order-print-hb-modal', $event.target)
            }
          })


      /*
      const form = document.createElement('form');
      form.target = '_blank';
      form.method = "post";
      form.action = "https://api.kitapfirsatlari.com/hepsijet/etiket";
      document.body.appendChild(form);

      const formField = document.createElement('input');
      formField.type = 'hidden';
      formField.name = 'ids';
      formField.value = this._selectedOrders
      form.appendChild(formField);
      form.submit();
      form.remove();
      */
    },
    getOrderBasketNames() {
      this.$store.commit('order/setFilterBasket', "");
      this.$store.dispatch('orderBasket/getAll', 'live');
    },
    filterBasket(basketName) {
      //siparişleri göstermek için kullanılır
      //seçilen sepetteki siparişler listede gösterilir
      this.$store.commit('order/setFilterBasket', basketName);
    },
    filterBasket2(selected) {
      //sepetleri filterelemek için kullanılır
      //seçilen sepete tagine göre sadece o sepet isimleri listelenir.
      console.log(selected)
    },
    getOrderStatusCount() {
      this.$store.dispatch('order/getOrderStatusCounts');
    }
  },
  created() {
    this.$store.dispatch('orderBasket/getAll');
    setInterval(() => {
      this.$store.dispatch('orderBasket/getAll');
    }, 60000)
  }
}
</script>

<style scoped>
</style>
