import { render, staticRenderFns } from "./EditProductBarcodes.vue?vue&type=template&id=10eb908c&scoped=true&"
import script from "./EditProductBarcodes.vue?vue&type=script&lang=js&"
export * from "./EditProductBarcodes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10eb908c",
  null
  
)

export default component.exports