<template>
  <b-overlay :show="show" rounded="sm">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="10">
          <b-row>
            <!-- Form Elementleri Buraya Gelecek -->
            <b-col md="2" class="p-0 m-0">
              <b-form-input type="number" size="sm" v-model="_search.id" placeholder="Ürün No"/>
              <b-form-input type="text" size="sm" v-model="_search.code" placeholder="Ürün Kodu"/>
            </b-col>

            <b-col md="2" class="p-0 m-0">
              <b-form-input type="text" size="sm" v-model="_search.barcode" placeholder="Ürün Barkod"/>
              <b-form-input type="text" size="sm" v-model="_search.name" placeholder="Ürün Adı"/>
            </b-col>

            <b-col md="2" class="p-0 m-0">
              <v-select
                  v-model="_search.isBundle"
                  label="text"
                  :options="[{value:'all',text:'Hepsi'},{value:'1',text:'Takım'},{value:'0',text:'Tekil'}]"
                  :reduce="name => name.value"
                  class="select-size-sm"
                  placeholder="Takım Ürün"
              />
              <vue-autosuggest
                  :suggestions="filteredBrands"
                  :limit="limit"
                  :input-props="{id:'autosuggest__input',class:'form-control form-control-sm', placeholder:'Marka Seçiniz!'}"
                  @input="onInputChange"
                  @selected="selectHandler"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                </template>
              </vue-autosuggest>
              <b-badge v-for="(brand,key) in _search.brands" :key="brand.id"
                       @click="deleteBrand(key)">{{ brand.name }}
              </b-badge>
            </b-col>

            <b-col md="2" class="p-0 m-0">
              <b-form-input type="number" size="sm" v-model="_search.hbId" placeholder="HB ID"/>
              <b-form-input type="text" size="sm" v-model="_search.hbSku" placeholder="HB SKU"/>
            </b-col>

            <b-col md="2" class="p-0 m-0">
              <b-form-input type="number" size="sm" v-model="_search.tyId" placeholder="TY ID"/>
              <b-form-input type="text" size="sm" v-model="_search.tySku" placeholder="TY SKU"/>
            </b-col>

            <b-col md="2" class="p-0 m-0">
              <b-form-input type="text" size="sm" v-model="_search.stock" placeholder="Stok -/+Adet"/>
              <v-select
                  v-model="_search.suppliers"
                  label="name"
                  multiple
                  :options="_suppliers"
                  :reduce="name => name.id"
                  class="select-size-sm"
                  placeholder="Tedarikçi"
              />
            </b-col>

            <b-col md="2" class="p-0 m-0">
              <v-select
                  v-model="_search.limit"
                  :options="[1,5,10,20,50,100,250,500]"
                  class="select-size-sm"
                  placeholder="Limit"
              />
              <b-form-input type="number" size="sm" v-model="_search.page" placeholder="Sayfa"/>
            </b-col>

            <b-col md="2" class="p-0 m-0">
              <b-form-textarea
                  id="textarea"
                  v-model="_search.ids"
                  placeholder="Ürün ID leri , ile ayır"
                  rows="2"
                  max-rows="2"
              ></b-form-textarea>
            </b-col>

            <!-- Form Elementler Buraya Gelecek -->
          </b-row>
        </b-col>

        <b-col md="2">
          <b-button-group size="sm">
            <b-button variant="primary" @click="getAll(1)" title="Getir">
              <feather-icon icon="FilterIcon"></feather-icon>
            </b-button>
            <b-button variant="danger" @click="clearSearch" title="Temizle">
              <feather-icon icon="XCircleIcon"></feather-icon>
            </b-button>
            <b-button variant="success" @click="addNewProduct($event,0)" title="Yeni Ürün Ekle">
              <feather-icon icon="PlusIcon"></feather-icon>
            </b-button>
            <b-button variant="success" @click="addNewProduct($event,1)" title="Yeni Takım Ürün Ekle">
              <feather-icon icon="PlusSquareIcon"></feather-icon>
            </b-button>
          </b-button-group>
          <b-button-group size="sm">
            <b-dropdown size="sm" text="Seçenek" variant="outline-dark">
              <b-form-checkbox-group
                  v-model="$store.state.product.search.selectedOptions"
                  :options="$store.state.product.optionsView"
                  switches
                  class="demo-inline-spacing"
              />
            </b-dropdown>
            <b-dropdown size="sm" text="Tercih" variant="outline-dark">
              <b-form-checkbox-group
                  v-model="$store.state.product.selectedFilter"
                  :options="_filterView"
                  switches
                  class="demo-inline-spacing"
              />
            </b-dropdown>
          </b-button-group>
          <div>
            <b-checkbox v-model="_search.is_not_tax">KDV siz (Siparişli)</b-checkbox>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <Pagination :pagination="_pagination" @pageClick="getAll"/>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {mapGetters} from "vuex";
import vSelect from "vue-select";
import {VueAutosuggest} from 'vue-autosuggest'
import Pagination from "@/components/Pagination";


export default {
  name: "Search",
  components: {vSelect, VueAutosuggest, Pagination},
  computed: {
    ...mapGetters('product', ["_search", "_filterView", "_pagination"]),
    ...mapGetters('supplier', ["_suppliers", "_listSuppliers"]),
    ...mapGetters('productBrand', ["_brands"]),
  },
  data: () => ({
    show: false,
    filteredBrands: [],
    limit: 20
  }),
  methods: {
    addNewProduct(event, isBundle) {
      this.$store.commit('product/setEditProduct', {isBundle, sub_products: []});
      this.$root.$emit('bv::show::modal', 'edit-product-modal', event.target)
    },
    deleteBrand(key) {
      this.$store.commit('product/deleteBrand', key)
    },
    onInputChange(text) {
      if (text === '' || text === undefined) return
      const data = this._brands.filter(brand => brand.name.toLowerCase().indexOf(text.toLowerCase()) > -1).splice(0, this.limit);
      this.filteredBrands = [{data}]
    },
    selectHandler(option) {
      this.$store.state.product.search.brands.push(option.item);
    },
    getAll(page) {
      this.show = true
      this.$store.state.product.search.page = page;
      this.$store.commit('product/setProducts', []);
      this.$store.dispatch('product/getAll')
          .then(res => {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ürünler Geldi',
                variant: res,
                icon: 'ArrowDownCircleIcon',
              },
            })
          })
    },
    clearSearch() {
      this.$store.commit('product/setSearchNull')
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
