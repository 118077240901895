<template>
  <tr :class="tableClass">
    <td>{{ product.store_id }}</td>
    <td>{{ product.supplier_id }}</td>
    <td>{{ product.stock }}</td>
    <td>{{ product.price }}</td>
    <td>{{ product.deadline }}</td>
  </tr>
</template>

<script>
import {BBadge} from "bootstrap-vue";

export default {
  name: "Product",
  components: {BBadge},
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    tableClass() {
      if (["1", "2"].indexOf(this.product.status)) {
        return '';
      }
      return 'table-warning';
    }
  }
}
</script>

<style scoped>

</style>
