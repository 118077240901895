<template>
  <b-modal
      id="product-images-modal"
      ref="product-images-modal"
      cancel-variant="outline-secondary"
      cancel-title="Kapat"
      title="Ürün Resimleri"
      size="lg"
      @hidden="hidden"
  >
    <b-row class="match-height">
      <b-col md="6" lg="4" v-for="(image,key) in _editProduct.images" :key="key">
        <b-card :img-src="'https://api.kitapfirsatlari.com/'+image.url" img-top>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import {BImg} from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import {mapGetters} from "vuex";

export default {
  name: "ProductImages2",
  components: {Swiper, SwiperSlide, BImg,},
  data: () => ({
    swiperOptions: {
      slidesPerView: 3,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    },
  }),
  computed: {
    ...mapGetters("product", ["_editProduct"]),
  },
  methods: {
    hidden() {
      this.$store.commit('product/setEditProduct', {})
    }
  }
}
</script>

<style scoped>

</style>
