<template>
  <b-modal
      id="edit-bundle-add-product-home-modal"
      ref="edit-bundle-add-product-home-modal"
      cancel-variant="outline-secondary"
      cancel-title="Kapat"
      title="Takım Ürüne Ürün Ekle"
      size="xl"
  >
    <div>
      <ProductSearch/>
    </div>
    <div>
      <section id="items">
        <b-row id="table-hover-row">
          <b-col md="12">
            <div class="table-responsive">
              <Products pagaName="addBundle"/>
            </div>
          </b-col>
        </b-row>
      </section>
    </div>
  </b-modal>
</template>

<script>
import ProductSearch from "@/views/product/Search";
import Products from "@/views/product/Products";

export default {
  name: "EditBundleAddProductHome",
  components: {ProductSearch, Products}
}
</script>

<style scoped>

</style>
