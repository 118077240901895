<template>
  <b-tab title="Ürün">
    <b-card-text>
      <b-form-group label-cols="4" label="Ürün No" size="sm">
        <b-form-input type="text" size="sm" v-model="product.id" disabled/>
      </b-form-group>
      <b-form-group label-cols="4" label="Ürün Kodu" size="sm">
        <b-form-input type="text" size="sm" v-model="product.code" disabled/>
      </b-form-group>
      <b-form-group label-cols="4" label="Ürün Adı" size="sm">
        <validation-provider #default="{ errors }" name="ürün adı" rules="required">
          <b-form-input type="text" size="sm" v-model="product.name" :state="errors.length > 0 ? false:null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group label-cols="4" label="Ürün Barkod" size="sm">
        <validation-provider #default="{ errors }" name="ürün barkod" rules="required">
          <b-form-input type="text" size="sm" v-model="product.barcode" :state="errors.length > 0 ? false:null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <fieldset class="form-group" size="sm" v-if="product.brand">
        <div class="form-row">
          <legend tabindex="-1" class="col-4 bv-no-focus-ring col-form-label">
            Marka
            <b-badge variant="success" size="sm" @click="editBrand">Edit</b-badge>
          </legend>
          <div class="col">
            <input type="text" disabled="disabled" class="form-control form-control-sm" :value="product.brand.name">
          </div>
        </div>
      </fieldset>
      <b-form-group label-cols="4" label="Marka" size="sm" v-else>
        <validation-provider #default="{ errors }" name="marka" rules="required">
          <vue-autosuggest
              :suggestions="filteredBrands"
              :limit="limit"
              :input-props="{id:'autosuggest__input',class:'form-control form-control-sm', placeholder:'Marka Seçiniz!'}"
              @input="onInputChange"
              @selected="selectHandler"
          >
            <template slot-scope="{suggestion}">
              <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
            </template>
          </vue-autosuggest>
        </validation-provider>
      </b-form-group>

      <b-form-group label-cols="4" size="sm">
        <b-badge variant="" @click="addNewBrand">Marka Oluştur</b-badge>
      </b-form-group>


      <b-form-group label-cols="4" label="KDV Oranı" size="sm">
        <validation-provider #default="{ errors }" name="Kdv Oranı" rules="required">
          <!--<b-form-input type="number" size="sm" v-model="product.tax_rate" :state="errors.length > 0 ? false:null"/>-->
          <b-form-radio-group
              v-model="product.tax_rate"
              :options="_taxes"
              class="demo-inline-spacing"
              name="radio-inline"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>


      <b-form-group label-cols="4" label="Ürün Fiyatı" size="sm">
        <validation-provider #default="{ errors }" name="ürün Fiyatı" rules="required">
          <b-form-input type="number" size="sm" v-model="product.price" :state="errors.length > 0 ? false:null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label-cols="4" label="Ürün sabit ilave" size="sm">
        <validation-provider #default="{ errors }" name="ürün sabit ilave" rules="required">
          <b-form-input type="number" size="sm" v-model="product.fixed_const" :state="errors.length > 0 ? false:null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label-cols="4" label="Ürün Açıklama" size="sm">
        <b-form-textarea id="textarea-default" placeholder="Textarea" v-model="product.description"/>
      </b-form-group>

      <b-form-group label-cols="4" label="Bilgiler Kimden Alındı" size="sm">
        <b-form-input type="text" size="sm" :value="supplier_name(product.supplier_id)" disabled/>
      </b-form-group>


      <b-form-group label-cols="4" label="Ekleme Tarihi" size="sm">
        <b-form-input type="text" size="sm"
                      :value="new Date(product.created_at).toLocaleString('tr-TR', {dateStyle: 'short',timeStyle: 'short',})"
                      disabled
        />
      </b-form-group>

      <b-form-group label-cols="4" label="Değiştirme Tarihi" size="sm">
        <b-form-input type="text" size="sm"
                      :value="new Date(product.updated_at).toLocaleString('tr-TR', {dateStyle: 'short',timeStyle: 'short',})"
                      disabled
        />
      </b-form-group>
    </b-card-text>
  </b-tab>
</template>

<script>
import supplierMixins from "@/mixins/supplierMixins";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email, confirmed, password} from '@validations'
import {VueAutosuggest} from 'vue-autosuggest'
import {mapGetters} from "vuex";

export default {
  name: "EditProduct",
  mixins: [supplierMixins],
  components: {ValidationObserver, ValidationProvider, VueAutosuggest},
  data: () => ({
    taxRateOptions: [
      {text: "%0", value: 0},
      {text: "%1", value: 1},
      {text: "%8", value: 8},
      {text: "%18", value: 18},
    ],
    filteredBrands: [],
    limit: 20,
  }),
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('productBrand', ["_brands"]),
    ...mapGetters('app', ['_taxes'])
  },
  methods: {
    selectHandler(option) {
      this.product.brand_id = option.item.id
      this.product.brand = option.item
    },
    onInputChange(text) {
      if (text === '' || text === undefined) return
      const data = this._brands.filter(brand => brand.name.toLowerCase().indexOf(text.toLowerCase()) > -1).splice(0, this.limit);
      this.filteredBrands = [{data}]
    },
    editBrand() {
      this.product.brand = null;
    },
    addNewBrand() {
      this.$store.commit('productBrand/setEditBrand', {"id": null, "name": "", "status": 1});
      this.$root.$emit('bv::show::modal', 'product-brand-edit-modal', '#btnShow')
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
