import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('orderStatus', ["_orderStatuses"]),
    },
    methods: {
        order_status(id) {
            let item = this._orderStatuses.find(item => item.id == id)
            if (!item) return 'Belirsiz'
            return item.status
        }
    }
}
