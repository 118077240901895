<template>
  <b-col md="12" style="margin-top: 5px">
    <b-button
        v-for="(stock,index) in product.stocks"
        :key="stock.id"
        size="sm"
        :variant="stock.stock_return===50?'outline-secondary':'outline-warning'"
        @click="selectStocks(stock)"
        v-if="stock.quantity>0"
        :disabled="clickedBtn || stock.quantity<product.quantity || [9,27,50].includes(product.supplier_id) || index>0"
        :title="supplier_name(stock.supplier_id) + ' ' + stock.shelve_name"
    ><b>{{ supplier_name(stock.stock_return) }} - {{ stock.price }} ₺ - {{ stock.quantity }}
      Ad.</b>
    </b-button>
  </b-col>
</template>

<script>
import supplierMixins from '@/mixins/supplierMixins'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'OrderProductStock',
  mixins: [supplierMixins],
  data: () => ({
    clickedBtn: false,
  }),
  props: {
    order: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    selectStocks(stock) {
      this.clickedBtn = true
      let stockCard = {
        stock_return: stock.stock_return,
        parent_id: stock.stock_card_id,
        product_id: stock.product_id,
        order_product_id: this.product.id,
        supplier_id: stock.supplier_id,
        shelve_name: stock.shelve_name,
        in_out: '-',
        quantity: this.product.quantity,
        market_price: stock.market_price,
        price: stock.price,
        status: 1,
        add_user_id: null
      }
      this.$store.dispatch('order/updateOrderProduct', {
        id: this.product.id,
        product: {
          supplier_id: stock.stock_return,
          stockCard: stockCard
        },

      })
          .then(res => {
            if (res.order) {
              this.order.order_status_id = res.order.order_status_id;
              this.order.status = res.order.status;
            }

            this.supplierChangeShow = false
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    },
  }
}
</script>

<style scoped>

</style>
