<template>
  <div style="">
    <b-button @click="ImageModal">
      <b-carousel
          id="carousel-1"
          ref="myCarousel"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="60"
          img-height="80"
          style="width:80px;height: 160px"
      >
        <b-carousel-slide v-for="(image,index) in images" :img-src="prefix + image.url" :key="index"/>
      </b-carousel>
    </b-button>
    <!-- programmatically  -->
    <div class="d-flex align-items-center justify-content-center mt-1" v-if="images.length>1">
      <b-button-group size="sm">
        <b-button
            variant="outline-primary"
            @click="prev"
        >
          <feather-icon icon="SkipBackIcon"/>
        </b-button>
        <b-button
            variant="outline-primary"
            @click="start"
        >
          <feather-icon :icon="status ? 'PlayCircleIcon' : 'StopCircleIcon'"/>
        </b-button>
        <b-button
            variant="outline-primary"
            @click="next"
        >
          <feather-icon icon="SkipForwardIcon"/>
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>


export default {
  name: "ProductImages",
  data: () => ({
    status: false,
  }),
  props: {
    images: {
      type: Array,
      required: true
    },
    prefix: {
      type: String,
      default: ""
    }
  },
  methods: {
    ImageModal() {
      alert('Resim Büyütme Yapılacak')
    },
    prev() {
      if (!this.status) {
        this.$refs.myCarousel.prev()
      }
    },
    next() {
      if (!this.status) {
        this.$refs.myCarousel.next()
      }
    },
    start() {
      this.status = !this.status
      if (this.status) {
        this.$refs.myCarousel.pause()
      } else {
        this.$refs.myCarousel.start()
      }
    },
  },
}
</script>

<style scoped>

</style>
