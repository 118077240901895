export default {
    methods: {
        addNewOrder(productId) {
            this.$swal({
                title: 'Ürün Adedi Giriniz',
                input: 'number',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
                inputAttributes: {
                    autocapitalize: 'off',
                },
                showCancelButton: true,
                confirmButtonText: 'Sipariş Oluştur',
                showLoaderOnConfirm: true,
                preConfirm: (quantity) => {
                    if (!quantity) return null
                    return this.$store.dispatch('order/addNewOrder', {
                        store_id: 7,
                        product_id: productId,
                        quantity
                    })
                        .then(response => {
                            return response
                        })
                        .catch(error => {
                            return this.$swal.showValidationMessage(`Request failed:  ${error}`)
                        })
                },
            }).then(result => {
                if (result.value) {
                    this.$swal({
                        title: result.value.message,
                        imageUrl: result.value.avatar_url,
                        customClass: {confirmButton: 'btn btn-primary'},
                        confirmButtonText: 'Tamam',
                    })
                }
            })
        },
    }

}
