<template>
  <tr>
    <td v-if="editStatus">{{ publisher.id }}</td>
    <td>{{ publisher.publisher_name }}</td>
    <td v-if="editStatus">{{ publisher.status }}</td>
    <td v-if="editStatus">
      <b-button-group>
        <b-button
            variant="warning"
            size="sm"
            @click="showModal"
        >
          Düzenle
        </b-button>
        <b-button
            variant="danger"
            size="sm"
            @click="destroy"
        >
          Sil
        </b-button>
      </b-button-group>

    </td>
  </tr>
</template>

<script>
import {BButtonGroup, BButton} from "bootstrap-vue";

export default {
  name: "Publisher",
  components: {BButtonGroup, BButton},
  props: {
    publisher: {
      type: Object,
      required: true
    },
    editStatus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showModal() {
      this.$store.commit('productPublisher/setSelected', this.publisher);
      this.$root.$emit('bv::show::modal', 'edit-publisher-modal', '#btnShow')
    },
    destroy() {
      if (confirm("Silmek istediğinden emin misiniz?") == true) {
        this.$store.dispatch('productPublisher/destroy', this.publisher.id)
      }
    }
  }
}
</script>

<style scoped>

</style>
