<template>
  <b-modal ref="order-product-supplier-select"
           id="order-product-supplier-select"
           hide-footer
           size="lg"
           no-close-on-backdrop
           title="Fiyat Giriş Ekranı">
    <b-overlay :show="show" rounded="sm">
      <div class="d-block text-center">

        <b-table-lite hover
                      :fields="fields"
                      :items="_supplierProducts"
                      :tbody-tr-class="rowClass"
        >
          <template #cell(iskonto)="data">
             <span class="text-nowrap" v-if="data.item.price && data.item.market_price">
               % {{ (((data.item.market_price - data.item.price) / data.item.market_price) * 100).toFixed(1) }}
            </span>
            <span class="text-nowrap" v-else>
             -
            </span>
          </template>
          <template #cell(price)="data">
            <span class="text-nowrap">
              {{ data.item.currency ? data.item.currency : 'TL' }} {{ data.item.price }}
            </span>
          </template>

          <template #cell(actions)="data">
            <b-button size="sm" variant="outline-primary" @click="matching(data.item)">Eşitle</b-button>
          </template>
        </b-table-lite>
        <hr>
        <table>
          <thead>
          <th>Piyasa <br>
            Fiyatı (TL)
          </th>
          <th>İskonto<br>
            (%)
          </th>

          <th>Kdv<br>(%)</th>
          <th>Alış Fiyatı <br>
            (KDV'siz) (TL)
          </th>

          <th>Kdv <br>
            Dahil
          </th>

          <th>Alış Fiyatı <br>
            (KDV'li) (TL)
          </th>
          <th>İşlem</th>
          </thead>
          <tbody>
          <tr>
            <td>
              <b-input v-model="data2.market_price" @blur="marketpriceHesapla" :disabled="!data2.isVat"/>
            </td>
            <td>
              <b-input v-model="data2.iskonto" @blur="iskontoHesap" :disabled="!data2.isVat"/>
            </td>
            <td>
              <v-select v-model="data2.vat"
                        label="title" :options="[0,1,8,18]"
                        @option:selected="kdvHesap"
                        :disabled="data2.isVat"/>
            </td>
            <td>
              <b-input @blur="alisHesap2" v-model="data2.pricekdv" :disabled="data2.isVat"/>
            </td>

            <td>
              <b-form-checkbox v-model="data2.isVat" name="check-button" switch inline @change="yenidenKdv"/>
            </td>
            <td>
              <b-input v-model="data2.price" @blur="alisHesap" :disabled="!data2.isVat"/>
            </td>
            <td>
              <b-button :disabled="!data2.price" size="sm" variant="primary" @click="saveSupplier">Kaydet</b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "OrderProductSupplierProductSelecet",
  components: {vSelect},
  data: () => ({
    show: false,
    data2: {
      market_price: null,
      iskonto: null,
      price: null,
      pricekdv: null,
      vat: 0,
      isVat: true
    },
    data: {},
    fields: [
      {key: 'supplier.name', label: 'TEDARİKÇİ'},
      {key: 'stock', label: 'Stok'},
      {key: 'market_price', label: 'PSF (TL)'},
      {key: 'iskonto', label: 'İSKONTO (%)'},
      {key: 'price', label: 'ALIŞ (TL)'},
      {key: 'actions', label: 'YÖNET'},
    ]
  }),
  computed: {
    ...mapGetters('supplierProduct', ["_supplierProducts"]),
  },
  methods: {
    rowClass(item) {
      if (item.supplier_id == this.data.supplier.id) return 'table-success'
      return ''
    },
    marketpriceHesapla($event) {
      this.data2.price = ($event.target.value - ($event.target.value * this.data2.iskonto / 100)).toFixed(2)
    },
    iskontoHesap($event) {
      this.data2.price = (this.data2.market_price - (this.data2.market_price * $event.target.value / 100)).toFixed(2)
    },
    alisHesap($event) {
      if (this.data2.market_price > 0)
        this.data2.iskonto = (((this.data2.market_price - $event.target.value) / this.data2.market_price) * 100).toFixed(2);
    },
    alisHesap2($event) {
      this.data2.price = (parseFloat($event.target.value) + ((parseFloat($event.target.value) * parseInt(this.data2.vat)) / 100)).toFixed(2)

      if (this.data2.market_price > 0)
        this.data2.iskonto = (((this.data2.market_price - this.data2.price) / this.data2.market_price) * 100).toFixed(2);
    },
    yenidenKdv() {
      this.data2.price = null
    },
    kdvHesap(selectedOption) {
      if (this.data2.pricekdv > 0)
        this.data2.price = (parseFloat(this.data2.pricekdv) + ((parseFloat(this.data2.pricekdv) * parseInt(selectedOption)) / 100)).toFixed(2)

      if (this.data2.market_price > 0)
        this.data2.iskonto = (((this.data2.market_price - this.data2.price) / this.data2.market_price) * 100).toFixed(2);
    },
    matching(item) {
      this.data2.market_price = item.market_price;
      if (item.market_price > 0)
        this.data2.iskonto = (((item.market_price - item.price) / item.market_price) * 100).toFixed(1);
      this.data2.price = item.price;
    },
    saveSupplier() {
      this.show = true
      if (this.data.product.supplier_id == this.data.supplier.id) {
        this.$store.dispatch('order/updateOrderProductPrice', {
          id: this.data.product.id,
          price: {selected_purchase_price: this.data2.price}
        })
            .then(res => {
              this.show = false
              this.selectedSupplier = 0
              this.selectedSupplierPrice = 0
              this.$toast({
                component: ToastificationContent,
                props: res
              })
              if (res.variant == 'success') {
                this.$root.$emit('bv::hide::modal', 'order-product-supplier-select', '#btnShow')
              }
            })
        return
      } else {
        this.$store.dispatch('order/updateOrderProduct', {
          id: this.data.product.id,
          product: {
            supplier_id: this.data.supplier.id,
            supplier_price: this.data2.price,
          },
        })
            .then(res => {
              this.show = false
              this.selectedSupplier = 0
              this.selectedSupplierPrice = 0
              this.$toast({
                component: ToastificationContent,
                props: res
              })
              if (res.variant == 'success') {
                this.$root.$emit('bv::hide::modal', 'order-product-supplier-select')
              }
            })
      }
    }
  },
  mounted() {
    this.$root.$on('openOrderProductSupplierSelect', (data) => {
          this.data2 = {
            market_price: null,
            iskonto: null,
            price: null,
            pricekdv: null,
            vat: 0,
            isVat: true
          }
          this.data = data
          this.$store.commit('supplierProduct/clearSearch');
          this.$store.state.supplierProduct.search.product.product_id = data.product.product_id
          this.$store.dispatch('supplierProduct/getAll');
        }
    )
  }
}
</script>

<style scoped>

</style>
