<template>
  <b-card :style="orderStyle">
    <b-row v-if="order.products.length<order.products[0].order_product_count">
      <b-col>
        <b-badge variant="danger">Dikkat bu siparişin tüm ürünleri henüz çekilmedi ürün seçimi yapmayın</b-badge>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="2">
        <b-row>
          <b-col cols="12">
            <b-form-checkbox
                v-model="$store.state.order.selectedOrders"
                :value="order.id"
                class="custom-control-secondary"
            />
            <feather-icon icon="CopyIcon" @click="doCopy(order.id)"/>
            Sip. ID: <span class="font-weight-bold text-nowrap">{{ order.id }}</span>
          </b-col>

          <b-col cols="12">
            <feather-icon icon="CopyIcon" @click="doCopy(order.order_number)"/>
            Sip. No: <span class="font-weight-bold text-nowrap">{{ order.order_number }}</span>
          </b-col>

          <b-col cols="12">
            <feather-icon icon="CopyIcon" @click="doCopy(order.package_number)"/>
            Pak. No: <span class="font-weight-bold text-nowrap">{{ order.package_number }}</span>
          </b-col>

          <b-col cols="12">
            <div v-if="[7,8,9,10,11,12,13,14,15,16,17,18,19,20].includes(order.store_id)">
              <b-button-group>
                <b-button size="sm"
                          :href="'https://sellercentral.amazon.com.tr/orders-v3/order/'+order.order_number"
                          target="newamazon"
                >
                  <feather-icon icon="LinkIcon"/>
                </b-button>
                <b-button v-if="order.exclusion" @click="delToExclusion" size="sm" variant="primary"
                          v-b-tooltip.hover.v-info
                          title="Sipariş Durum Değişimi Kilidi kaldır"
                >
                  <feather-icon icon="UnlockIcon"/>
                </b-button>
                <b-button v-else @click="addToExclusion" size="sm" variant="secondary"
                          v-b-tooltip.hover.v-info
                          title="Sipariş Durum Değişimi Kilitle"
                >
                  <feather-icon icon="LockIcon"/>
                </b-button>
              </b-button-group>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="m-0 p-0">
            <b-badge>{{ order.fulfillment_channel }}</b-badge>
          </b-col>
          <b-col cols="12">
            <hr class="m-0 p-0">
            <b-badge variant="" style="min-width: 50px">Toplam:&nbsp; {{ order.total_price }}₺</b-badge>
            <b-badge variant="" style="min-width: 50px" v-if="order.total_price!=order.sales_price">İnd.
              Top.:&nbsp; {{
                order.sales_price
              }}
            </b-badge>
          </b-col>
          <b-col cols="12">
            <hr class="m-0 p-0">
            <b-button size="sm" variant="info" @click="showOrderTimeLine">Geçmiş</b-button>
            <b-button size="sm" class="ml-1" v-if="!order.is_follow" variant="secondary" @click="setFollow(1)">
              <feather-icon icon="LockIcon"/>
              Takip
            </b-button>
            <b-button size="sm" class="ml-1" v-else variant="primary" @click="setFollow(0)">
              <feather-icon icon="UnlockIcon"/>
              Takip
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr class="m-0 p-0">

            <OrderOrderAccounting
                :order="order"
                v-if="userData.roles.includes('manager') && _search.profit"
            />

            <!--
            <OrderOrderAccountingHb
                :order="order"
                v-if="userData.roles.includes('manager') && _search.profit && [1,2,4].includes(order.store_id)"
            />
            <OrderOrderAccounting
                :order="order"
                v-if="userData.roles.includes('manager') && _search.profit   && [3,5,6,7].includes(order.store_id)"
            />
            -->

          </b-col>
          <b-col cols="12">
            <hr class="m-0 p-0">
            <Accounting v-if="order.accountings" :accountings="order.accountings"/>
          </b-col>
          <b-col cols="12">
            <div v-if="order.invoice_basket && [8].includes(order.order_status_id)">
              <b-button variant="danger" @click="delFormCard">Sepetten Sil</b-button>
            </div>
            <div v-else-if="order.invoice_basket">
              <b-button variant="success">{{ order.invoice_basket.basket_name }}</b-button>
              <b-button variant="warning" @click="addToCardBack">Geri Al</b-button>
            </div>
            <div v-else>
              <b-button
                  v-if="isActiveAddToCard"
                  variant="danger"
                  @click="addToCard('manuel')"
                  :disabled="isDisbaledAddToCard"
              >Sepete Ekle
              </b-button>
            </div>
            <b-badge :variant="addBasketBtnMesaj.variant">{{ addBasketBtnMesaj.title }}</b-badge>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6" class="p-0">
        <OrderListProduct
            v-for="(product,index) in order.products"
            :indexNo="index+1"
            :productCount="productCount"
            :key="product.id"
            :order="order"
            :product="product"
        />
      </b-col>
      <b-col md="2" class="m-0">
        <b-overlay
            :show="statusChangeShow"
            rounded="sm"
        >
          <div v-if="!order.status.id">
            <b-form-group label="Sipariş Durumu">
              <v-select
                  label="status"
                  :options="_orderStatuses"
                  :reduce="status => status.id"
                  class="select-size-sm"
                  @option:selected="selectedOption"
              />
            </b-form-group>
          </div>

          <b-badge
              v-else
              :variant="order.status.status_color"
              class="badge-glow"
              @dblclick="changeOrderStatus"
          >
            {{ order.status.status }}
          </b-badge> &nbsp;
          <b-badge
              :variant="order.status.color"
              class="badge-glow"
          >
            {{ order.order_status }}
          </b-badge>
        </b-overlay>
        <hr class="m-0 p-0">
        <div v-if="order.customer">
          <feather-icon icon="CopyIcon" @click="doCopy(order.customer_name)"/>
          <b>{{ order.customer_name }}</b><br>
          <b-badge v-if="order.is_commercial" variant="danger">Kurumsal Müşteri</b-badge>
          <br>
          <b v-if="order.customer_name!=order.customer.shipment_name">
            <feather-icon icon="CopyIcon" @click="doCopy(order.customer.shipment_name)"/>
            {{ order.customer.shipment_name }}<br></b>
          {{ order.customer.shipment_city }} - {{ order.customer.shipment_district }}
        </div>
        <hr class="m-0 p-0">

        <img style="margin-top: 5px; margin-right: 10px" :src="'/img/py_logo/'+order.store.logo"
             :title="order.store.store_name" height="40px"
        >
        <b-badge variant="primary" size="xs" class="badge-glow">{{ order.store.store_name }}</b-badge>
        <br>
        <img
            v-if="order.tracking"
            style="margin-top: 5px"
            :src="'/img/kargo_logo/'+order.tracking.company.image_url"
            :title="order.tracking.company.name"
            width="100px"
        >
        <br>
        <b v-if="order.tracking">
          <feather-icon icon="CopyIcon" @click="doCopy(order.tracking.tracking_number)"/>
          {{ order.tracking.tracking_number }}
        </b>
      </b-col>
      <b-col md="2" class="m-0 p-0">
        <b-overlay :show="isButtonWorking">
          <b-button-group size="sm">
            <b-button v-if="order.tracking && order.tracking.cargo_company_id==4" variant="" size="sm"
                      @click="printLabelHb"
                      v-b-tooltip.hover.top="'Hepsijet Etiket Yazdır'"
            >
              <feather-icon icon="FileIcon"/>
            </b-button>
            <b-button v-else @click="labelPrint">
              <feather-icon
                  icon="FileIcon"
                  v-b-tooltip.hover.v-primary
                  title="Etiket Yazdır"
              />
            </b-button>

            <b-dropdown
                title="Sipariş Detayları"
                size="sm">
              <template #button-content>
                <feather-icon icon="FileTextIcon"/>
              </template>
              <b-dropdown-item size="sm" @click="sendInvoice">
                Fatura Kes
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown title="Sipariş Detayları" variant="" size="sm">
              <template #button-content>
                <feather-icon icon="EditIcon"/>
              </template>
              <b-dropdown-item>Sil</b-dropdown-item>
              <b-dropdown-item>Düzenle</b-dropdown-item>
              <b-dropdown-item>Güncelle</b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </b-overlay>
        <div>
          <span class="text-danger" v-if="invoiceError?true:false">{{ invoiceError }}</span>
        </div>

        <OrderListOrderInvoice v-for="invoice in order.invoices" :key="invoice.id" :invoice="invoice"/>
        <hr>
        <ViewDate name="Sipariş Tarihi &nbsp;&nbsp;: " :date="order.order_created_date"/>
        <b>
          <ViewDate
              variant="bg-secondary text-white"
              name="Son Tes. Tar.  &nbsp;&nbsp;: "
              :date="order.order_last_delivery_date"
          />
        </b>
        <br><br>
        <ViewDate name="Sipariş Ek.Tar. : " :date="order.created_at"/>
        <ViewDate name="Sip. Günc. Tar. : " :date="order.updated_at"/>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import OrderListProduct from '@/views/order/list/OrderListProduct'
import ViewDate from '@/views/common/ViewDate'
import {mapGetters} from 'vuex'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import copyMixins from '@/mixins/copyMixins'
import addToCardMixins from '@/mixins/addToCardMixins'
import axios from '@/libs/axios'
import Accounting from '@/views/order/list/Accounting.vue'
import OrderOrderAccounting from '@/views/order/list/OrderOrderAccounting'
import OrderOrderAccountingHb from '@/views/order/list/OrderOrderAccountingHb.vue'
import OrderListOrderInvoice from "@/views/order/list/OrderListOrderInvoice.vue";
import order from "@/store/order";

export default {
  name: 'OrderListOrder',
  components: {
    OrderOrderAccounting,
    OrderOrderAccountingHb,
    OrderListProduct,
    ViewDate,
    vSelect,
    Accounting,
    OrderListOrderInvoice
  },
  mixins: [copyMixins, addToCardMixins],
  data: () => ({
    titleName: 'Siparişler',
    userData: JSON.parse(localStorage.getItem('userData')),
    isButtonWorking: false,
    invoiceError: "",
    statusChangeShow: false,
    addBasketBtnMesaj: {
      title: '',
      variant: '',
    },
  }),
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('order', ['_selectedOrders', '_search']),
    ...mapGetters('orderStatus', ['_orderStatuses']),
    ...mapGetters('orderBasket', ['_selected']),
    productCount() {
      return this.order.products.length
    },
    isDisbaledAddToCard() {
      if (this.order.order_status_id != 13) return false;
      if (this.userData.roles.includes('super-admin')) return false;
      if (this.userData.roles.includes('admin')) return false;
      if (this.userData.roles.includes('manager')) return false;
      let status = true;
      this.order.products.forEach(product => {
        if (product.quantity > 1) status = false;
      });
      return status;
    },
    isActiveAddToCard() {
      if (this.order.products.length == 1 && [3, 13].includes(this.order.order_status_id)) {
        return true
      } else if (this.order.products.length > 1 && [3, 13].includes(this.order.order_status_id)) {
        let say = 0
        this.order.products.forEach(product => {
          if ([9, 27].includes(product.supplier_id)) {
            say++
          } else {
            if (product.come) {
              if (product.come.id) {
                say++
              }
            }
          }
        })
        if (say >= this.productCount) return true
      }
      return false
    },
    orderStyle() {
      if (this.order.micro) return 'background-color: rgb(255, 255, 146, 15%)'
      if (this.order.order_status_id == 2) return 'background-color: rgb(255, 146, 146, 15%)'
      if (this.order.order_status == 'Awaiting') return 'background-color: rgb(178 146 255 / 15%)'
      if (this.order.products.length > 0)
        if (this.order.products.length < this.order.products[0].order_product_count)
          return 'background-color:  rgb(178 146 255 / 30%)'
      return '';
    }
  },
  watch: {
    '$route.meta'(to) {
      if (to.pageName == 'invoice') {
        this.titleName = 'Fatura Sepeti'
      } else if (to.pageName == 'preparing') {
        this.titleName = 'Hazırlanıyor'
      } else if (to.pageName == 'collet') {
        this.titleName = 'Toplanacaklar'
      } else {
        this.titleName = 'Siparişler'
      }
    }
  },
  methods: {
    setFollow(is_follow) {
      axios.put('/order/' + this.order.id, {is_follow})
          .then(res => {
            if (res.data.status == 'success') {
              this.order.is_follow = is_follow;
            }
          })
    },
    showOrderTimeLine($event) {
      this.$store.state.order.timelineOrderId = this.order.id
      this.$root.$emit('bv::show::modal', 'order-list-timeline-modal', $event.target)
    },
    labelPrint() {
      let orders = []
      if (this.order.store.marketplace_code === 'AZ') {
        if (!this.order.tracking.tracking_number || this.order.tracking.tracking_number.slice(0, 3) == 111) {
          axios.get(`/amazon/label/${this.order.id}`)
              .then(res => {
                if (res.data.status === 'success') {
                  this.order.tracking = res.data.content;
                  orders.push(this.order)
                  this.$root.$emit('order-print-label', orders)
                } else {
                  this.$swal({
                    title: 'Kargo Etiketi Çekme Hatası',
                    icon: 'error',
                    text: `"${res.data.errors[0].code}"  ${res.data.errors[0].message}`
                  })
                }
              })
        } else {
          orders.push(this.order)
          this.$root.$emit('order-print-label', orders)
        }
      } else {
        orders.push(this.order)
        this.$root.$emit('order-print-label', orders)
      }

    },
    printLabel($event) {
      axios.post('/timeline/label/print', {ids: [this.order.id]})
      this.$store.commit('order/setSelectedOrder', this.order.id)
      this.$root.$emit('bv::show::modal', 'order-print-modal', $event.target)
    },
    printLabelHb($event) {
      axios.post('/timeline/label/print', {ids: [this.order.id]})
      this.$store.commit('order/setSelectedOrder', this.order.id)
      this.$store.dispatch('orderCommonLabel/getAll', {ids: [this.order.id]})
          .then(res => {
            if (res) {
              this.$root.$emit('bv::show::modal', 'order-print-hb-modal', $event.target)
            }
          })
    },
    changeOrderStatus() {
      this.order.status = {}
    },
    selectedOption(orderStatus) {
      this.statusChangeShow = true
      this.$store.dispatch('order/updateOrder', {
        id: this.order.id,
        order: {
          order_status_id: orderStatus.id,
        },
        status: orderStatus
      })
          .then(res => {
            this.statusChangeShow = false
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    },
    addToExclusion() {
      this.$store.dispatch('order/addToExclusion', this.order.id)
    },
    delToExclusion() {
      this.$store.dispatch('order/delToExclusion', this.order.id)
    },
    sendInvoice() {
      this.isButtonWorking = true;
      this.invoiceError = "";

      this.$swal({
        title: 'Fatura Kes',
        icon: 'question',
        text: 'Fatura kesmek istediğinizden emin misiniz?',
        showDenyButton: true,
        denyButtonText: 'Hayır',
        confirmButtonText: 'Evet',
      })
          .then((result) => {
            if (result.isConfirmed) {
              axios.get('/invoice/send/' + this.order.id)
                  .then(res => {
                    if (res.data.status == 'success') {
                      this.order.invoices.push(res.data.content)
                    }
                    this.invoiceError = res.data.message
                    this.isButtonWorking = false;
                  })
            } else if (result.isDenied) {
              this.isButtonWorking = false;
            }
          });
    },

  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
</style>
