<template>
  <b-row>
    <b-col md="12" :style="'margin-left: '+isPaddig+'px;margin-top:1px'">
      <b-button
          :id="'popover-button-message'+message.id"
          variant="success"
          class="m-0"
          style="padding-top: 3px;padding-bottom: 3px;padding-left: 1px"
      >
        {{ message.message.slice(0, 20) }}
        <b-badge v-if="message.message.length>20" variant="danger" class="pull-right">....</b-badge>
      </b-button>
      <b-popover
          :target="'popover-button-message'+message.id"
          variant="secondary"
          triggers="focus"
          placement="top"
      >
        <template #title>
          <span>{{ userName(message.user_id) }} <b-badge @click="addNewMessage(message.id)"
                                                         class="btn pull-right text-right"
          >
          <feather-icon icon="Edit2Icon"/>
        </b-badge></span>
        </template>
        <span>{{ message.message }}</span>
        <hr>
        <span>
          <ViewDate :date="message.created_at"/>

        </span>
      </b-popover>
    </b-col>
    <b-col md="12">
      <OrderListProductMessages
          v-for="message in message.sub_messages"
          :key="message.id"
          :message="message"
          :product="product"
          :isPaddig="isPaddigP"
      />
    </b-col>
  </b-row>
</template>

<script>

import { mapGetters } from 'vuex'
import ViewDate from '@/views/common/ViewDate'

export default {
  name: 'OrderListProductMessages',
  components: { ViewDate },
  props: {
    message: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    isPaddig: {
      default: 0
    }
  },
  computed: {
    ...mapGetters('users', ['_users']),
    isPaddigP() {
      return this.isPaddig + 10
    }
  },
  methods: {
    userName(id) {
      let user = this._users.find(user => user.id == id)
      if (!user) return ''
      return user.short_name
    },
    addNewMessage(parent_id) {
      let message = prompt('Mesaj')
      message = message.trim()
      if (message == null || message == '') {
        alert('mesaj geçersiz')
      } else {
        let data = {
          id: this.product.id,
          product: {
            order_product_id: this.product.id,
            parent_id,
            message,
          }
        }
        this.$store.dispatch('order/crateOrderProductMessage', data)
      }
    }
  }
}
</script>

<style scoped>

</style>
