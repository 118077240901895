import {useSound} from "@vueuse/sound";
//import success1SoundWav from "@/assets/sound/success1.wav";
//import success2SoundWav from "@/assets/sound/success2.wav";
//import warning1SoundWav from "@/assets/sound/warning1.wav";
//import warning2SoundWav from "@/assets/sound/warning2.wav";
//import danger1SoundWav from "@/assets/sound/danger1.wav";
//import danger2SoundWav from "@/assets/sound/danger2.wav";

import success1SoundWav from "/public/sound/success1.wav";
import success2SoundWav from "/public/sound/success2.wav";
import warning1SoundWav from "/public/sound/warning1.wav";
import warning2SoundWav from "/public/sound/warning2.wav";
import danger1SoundWav from "/public/sound/danger1.wav";
import danger2SoundWav from "/public/sound/danger2.wav"

export default {
    methods: {
        autoPlay(status) {
            if (status == 'success') {
                this.success1Sound.play();
            } else if (status == 'success2') {
                this.success2Sound.play();
            } else if (status == 'warning') {
                this.warning1Sound.play()
            } else if (status == 'warning2') {
                this.warning2Sound.play()
            } else if (status == 'danger') {
                this.danger1Sound.play();
            } else if (status == 'danger2') {
                this.danger2Sound.play()
            } else {
                this.danger2Sound.play();
            }
        }
    },
    setup() {
        const success1Sound = useSound(success1SoundWav)
        const success2Sound = useSound(success2SoundWav)
        const warning1Sound = useSound(warning1SoundWav)
        const warning2Sound = useSound(warning2SoundWav)
        const danger1Sound = useSound(danger1SoundWav)
        const danger2Sound = useSound(danger2SoundWav)
        return {success1Sound, success2Sound, warning1Sound, warning2Sound, danger1Sound, danger2Sound,}
    },
}
