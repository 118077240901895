<template>
  <b-modal
      id="edit-product-modal"
      ref="edit-product-modal"
      cancel-variant="outline-secondary"
      :ok-title="okTitle"
      @ok="updateProduct"
      cancel-title="Kapat"
      :title="title"
      size="xl"
      @hidden="hidden"
  >
    <validation-observer ref="simpleRules">
      <form @submit.prevent>
        <b-tabs justified pills>
          <EditProduct :product="_editProduct"/>
          <EditProductImage :product="_editProduct"/>
          <EditBundle v-if="_editProduct.isBundle && _editProduct.id" :product="_editProduct"/>
        </b-tabs>
        <div v-text="message"></div>
      </form>
    </validation-observer>
    <ProductBrandEdit/>
    <EditBundleAddProductHome/>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email, confirmed, password} from '@validations'
import EditProduct from "@/views/product/EditProduct";
import EditProductImage from "@/views/product/EditProductImage";
import ProductBrandEdit from "@/views/product/brand/ProductBrandEdit";
import EditBundle from "@/views/product/EditBundle";
import EditBundleAddProductHome from "@/views/product/EditBundleAddProductHome";

export default {
  name: "Edit",
  components: {
    ValidationProvider,
    ValidationObserver,
    EditProduct,
    EditProductImage,
    ProductBrandEdit,
    EditBundle,
    EditBundleAddProductHome
  },
  data: () => ({
    message: null
  }),
  computed: {
    ...mapGetters("product", ["_editProduct"]),
    title() {
      let title = '';
      if (this._editProduct.isBundle) title += 'Takım Ürün';
      else title += 'Ürün';
      if (this._editProduct.id) title += " Düzenle"
      title += " Oluştur";
      return title;
    },
    okTitle() {
      if (this._editProduct.id) return "Güncelle"
      return "Kaydet"
    }
  },
  methods: {
    updateProduct(bvModalEvent) {
      this.message = null
      bvModalEvent.preventDefault()
      if (this._editProduct.id) {
        this.$store.dispatch('product/updateProduct', this._editProduct)
            .then(res => {
              this.message = res.message
            })
      } else {
        this.$store.dispatch('product/storeProduct', this._editProduct)
            .then(res => {
              this.message = res.message
            })
      }
    },
    hidden() {
      this.$store.commit('product/setEditProduct', {})
    }
  }
}
</script>

<style scoped>

</style>
