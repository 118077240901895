import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {EventBus} from "@/event-bus";
import soundMixins from "@/mixins/soundMixins";

export default {
    mixins: [soundMixins],
    methods: {
        addToCome() {
            this.addToComeBtnShow = true
            this.$store.dispatch('order/createOrderProductCome', {
                id: this.product.id,
                product: {
                    order_product_id: this.product.id,
                },
                come: {},
            })
                .then(res => {
                    if (res.come) {
                        EventBus.$emit('setComeNumber', {text: res.come.id, variant: res.variant})
                    }
                    EventBus.$emit('setEventMessage', {text: res.message, variant: res.variant})
                    this.addToComeBtnShow = false
                    this.addToComeBtnMesaj = {
                        title: res.message,
                        variant: res.variant,
                    }
                    this.autoPlay(res.variant);
                    this.$toast({
                        component: ToastificationContent,
                        props: res
                    })
                })
        },
        delToCome() {
            this.addToComeBtnShow = true
            this.$store.dispatch('order/delOrderProductCome', {id: this.product.id, come: {}})
                .then(res => {
                    this.addToComeBtnShow = false
                    this.addToComeBtnMesaj = {
                        title: '',
                        variant: '',
                    }

                    if (res.come) {
                        EventBus.$emit('setComeNumber', {text: res.come.id, variant: res.variant})
                    } else {
                        EventBus.$emit('setComeNumber', {text: '', variant: ''})
                    }
                    EventBus.$emit('setEventMessage', {text: res.title, variant: res.variant})

                    this.autoPlay(res.variant + '2');
                    this.$toast({
                        component: ToastificationContent,
                        props: res
                    })
                });
        },
    }
}
