<template>
  <table class="table table-sm" style="font-size:0.8em;font-weight:bold;">
    <thead>
    <tr class="table-secondary">
      <td style="text-align:center">Detay</td>
      <td style="text-align:center">Fiyat</td>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>
        Satış
        <span style="float:right;">:</span>
      </td>
      <td class="numberTd">{{ order.total_price }}</td>
    </tr>

    <tr>
      <td>
        Komisyon
        <span style="float:right;">:</span>
      </td>
      <td class="numberTd">
        (%{{
          (((productTotal.sumCommission * 1.20) / order.total_price) * 100).toFixed(1)
        }})
        {{ (productTotal.sumCommission * 1.20).toFixed(2) }}
      </td>
    </tr>

    </tbody>

  </table>
</template>

<script>
export default {
  name: "OrderOrderAccountingHb",
  props: {
    order: Object,
  },
  computed: {
    salesPrice() {
      return this.order.sales_price
    },
    fromSalesPrice() {
      return this.salesPrice - (this.productTotal.sumCommission + this.productTotal.sumCargo1)
    },
    productTotal() {
      let total = {
        sumCommission: 0,
        sumCargo1: 0,
        sumCalPrice: 0,
        sumSelPrice: 0,
      }

      this.order.products.forEach(product => {
        if (product.product_accounting) {
          total.sumCommission += product.product_accounting.actual_commission * product.quantity
          total.sumCargo1 += product.product_accounting.actual_cargo1 * product.quantity
          total.sumCalPrice += product.product_accounting.calculated_purchase_price * product.quantity
          total.sumSelPrice += product.product_accounting.selected_purchase_price * product.quantity
        }
      })

      return total

    },
  },
}
</script>

<style scoped>
.numberTd {
  text-align: right;
}
</style>
