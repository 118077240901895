<template>

</template>

<script>
export default {
  name: "addMpMatching",
  methods: {
    tyMatching(productId) {
      this.$swal({
        title: 'Pazaryeri SKU Girin',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Ekle',
        showLoaderOnConfirm: true,
        preConfirm: (sku) => {
          if (!sku) return null
          return this.$store.dispatch('product/addTyMatchingProduct', {productId, sku})
              .then(res => {
                return res;
              })
        },
      })
          .then(result => {
            if (result.value) {
              this.$swal({
                title: result.value.message,
                icon: result.value.status,
              })
            }
          })
    },
    hbMatching(productId) {
      this.$swal({
        title: 'Pazaryeri SKU Girin',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Ekle',
        showLoaderOnConfirm: true,
        preConfirm: (sku) => {
          if (!sku) return null
          return this.$store.dispatch('product/addHbMatchingProduct', {productId, sku})
              .then(res => {
                return res;
              })
        },
      })
          .then(result => {
            if (result.value) {
              this.$swal({
                title: result.value.message,
                icon: result.value.status,
              })
            }
          })
    },
    azMatching(productId) {
      this.$swal({
        title: 'Pazaryeri SKU Girin',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Ekle',
        showLoaderOnConfirm: true,
        preConfirm: (sku) => {
          if (!sku) return null
          return this.$store.dispatch('product/addAzMatchingProduct', {productId, sku})
              .then(res => {
                return res;
              })
        },
      })
          .then(result => {
            if (result.value) {
              this.$swal({
                title: result.value.message,
                icon: result.value.status,
              })
            }
          })
    },
  }
}
</script>

<style scoped>

</style>
