<template>
  <b-col md="12">
    <b-form-checkbox
        v-model="showZeroStock"
        checked="true"
        name="check-button"
        switch
        inline
    > &nbsp
    </b-form-checkbox>
    <a :href="supplierProduct.url"
       class="btn btn-sm "
       :class="classStatus(supplierProduct.supplier_id,productAccounting.calculated_supplier_id)"
       style="margin-right: 3px;padding-left: 1px;padding-right: 1px;"
       :style="styleStatus(supplierProduct.stock)"
       target="_blank"
       v-for="supplierProduct in product.supplier_products"
       :key="supplierProduct.id"
       v-if="![9,27,50].includes(supplierProduct.supplier_id) && (showZeroStock || supplierProduct.stock>0)"
    >
      {{ supplier_name(supplierProduct.supplier_id) }} - {{ supplierProduct.price }} ₺ - {{ supplierProduct.stock }}
      Ad.</a>
  </b-col>
</template>

<script>
import supplierMixins from '@/mixins/supplierMixins'

export default {
  name: 'OrderProductSupplierProduct',
  mixins: [supplierMixins],
  props: {
    product: {
      type: Object,
      required: true,
    },
    productAccounting: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showZeroStock: false,
  }),
  computed: {
    result() {
      return (this.productAccounting.actual_selling_price_net - (this.productAccounting.actual_commission + this.productAccounting.actual_cargo1)).toFixed(2)
    },

  },
  methods: {
    classStatus(supplierId, calculatedSupplierId) {
      if (supplierId == calculatedSupplierId) return 'btn-primary'
      return 'btn-outline-primary'
    },
    styleStatus(stock) {
      if (stock < 1) return 'opacity: 0.5;'
      return ''

    }
  },
}
</script>

<style scoped>

</style>
