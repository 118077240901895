<template>
  <b-tab title="Resim">

    <b-row class="match-height">
      <b-col md="6" lg="4" v-for="(image,key) in product.images" :key="key">
        <b-card :img-src="'https://api.kitapfirsatlari.com/'+image.url" img-top>
          <b-button variant="danger" @click="disableImage(image.id)">Sil</b-button>
        </b-card>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col cols="12">
        <input ref="file" type="file" style="display: none;" @change="onChange($event)" multiple>
        <div class="d-flex align-items-center justify-content-end">
          <b-button variant="info" size="sm" @click="$refs.file.click()"> Resim Seç</b-button>
          <b-button variant="success" size="sm" :disabled="selectedImages.length<1" @click="saveImages"> Kaydet
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="(image,key) in selectedImages" :key="key">
        <b-img width="300" :src="image"/>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "EditProductImage",
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    selectedImages: [],
  }),
  methods: {
    disableImage(id) {
      this.$swal({
        title: 'Resmi',
        text: "Silmek istediğiniden emin misiniz?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil',
        cancelButtonText: 'Hayır',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('product/disableProductImages', id)
              .then(res => {
                this.$toast({
                  component: ToastificationContent,
                  props: res,
                })
              })
        }
      })
    },
    onChange(e) {
      e.target.files.forEach(image => {
        this.selectedImages.push(URL.createObjectURL(image))
      })
    },
    saveImages() {
      this.$store.dispatch('product/saveProductImages', {productId: this.product.id, images: this.$refs.file.files})
          .then(res => {
            if (res.variant == 'success') {
              this.selectedImages = [];
            }
            this.$toast({
              component: ToastificationContent,
              props: res,
            })
          })
    }
  }

}
</script>

<style scoped>

</style>
