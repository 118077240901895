<template>
  <tr>
    <th>Sıra No</th>
    <th>Resim</th>
    <th>Ürün Bilgi</th>
    <th v-if="_selectedFilter.indexOf('product-publishers')>-1">Yayımevleri</th>
    <th v-if="_selectedFilter.indexOf('product-categories')>-1">Kategoriler</th>
    <th v-if="_selectedFilter.indexOf('supplier-product')>-1">Tedarikçi Ürünleri</th>

    <th v-if="_selectedFilter.indexOf('store-product')>-1">Mağaza Bilgileri</th>
    <th v-if="_selectedFilter.indexOf('product-detail')>-1">
      Tarih<br>
      (Ekleme/Düzeltme)
    </th>
    <th>Yönet</th>
  </tr>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TableHead",
  computed: {
    ...mapGetters("product", ["_selectedFilter"])
  }
}
</script>

<style scoped>

</style>
